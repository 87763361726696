import React from 'react'
import { useTheme } from '../hooks/useTheme'

const DarkTheme = React.lazy(() => import('./DarkTheme'))

export const ThemeProvider = ({ children }) => {
  const theme = useTheme()

  return (
    <>
      <React.Suspense fallback={<span />}>
        {theme === 'dark' ? <DarkTheme /> : null}
      </React.Suspense>
      {children}
    </>
  )
}
