import { blue, green, magenta } from '@ant-design/colors'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { timestampToElapsedTime } from '@tellonym/core/helpers'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_TARGET_GENDER,
} from '@tellonym/enums/lib/Tell'
import { Button, Input, Radio, Segmented, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router'
import { Prompt } from 'react-router'
import { getPermissions } from '../../app/selectors'
import { Box, colors, helpers, theme } from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { editVariance } from '../actionsV2'
import { getLanguage } from '../selectorsV2'
import { ArtificialTellStats } from './ArtificialTellStats'
import { ModalArtificialTellAnswers } from './ModalArtificialTellAnswers'

const useEditVariance = (variance) => {
  const dispatch = ReactRedux.useDispatch()
  const match = ReactRouter.useRouteMatch()

  return React.useCallback(
    (payload) => {
      dispatch(
        editVariance({
          id: variance.id,
          groupId: parseInt(match.params.groupId, 10),
          ...payload,
        })
      )
    },
    [dispatch, match.params.groupId, variance.id]
  )
}

const EditButton = ({
  isEditing,
  setIsEditing,
  onPressSave,
  resetInputValue,
}) => {
  if (isEditing === false) {
    return null
  }

  return (
    <Box flexDirection="row" width="100%" minWidth={100}>
      <Button
        size="small"
        className="success"
        icon={<CheckOutlined />}
        onClick={() => {
          setIsEditing(false)
          onPressSave()
        }}
        style={{ width: '50%', marginRight: 4 }}
      />
      <Button
        size="small"
        danger
        icon={<CloseOutlined />}
        onClick={() => {
          setIsEditing(false)
          resetInputValue()
        }}
        style={{ width: '50%' }}
      />
    </Box>
  )
}

const ArtificialTellItemActions = ({
  isEditing,
  setIsEditing,
  item,
  onPressSave,
  onPressItem,
  resetInputValue,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false)

  const editVariance = useEditVariance(item)

  const onPressDelete = () => {
    setIsDeleting(true)

    editVariance({
      status: ARTIFICIAL_TELL_STATUS.DELETED,
    })
  }

  return (
    <Box alignItems="flex-end" justifyContent="space-between" paddingLeft={12}>
      {!isEditing && (
        <Segmented
          block
          options={[
            {
              label: (
                <Typography.Text style={{ color: green[5] }}>⚥</Typography.Text>
              ),
              value: ARTIFICIAL_TELL_TARGET_GENDER.BOTH,
            },
            {
              label: (
                <Typography.Text style={{ color: blue[5] }}>♂</Typography.Text>
              ),
              value: ARTIFICIAL_TELL_TARGET_GENDER.MALE,
            },
            {
              label: (
                <Typography.Text
                  style={{ color: magenta[5], fontWeight: 'bold' }}>
                  ♀
                </Typography.Text>
              ),
              value: ARTIFICIAL_TELL_TARGET_GENDER.FEMALE,
            },
          ]}
          size="small"
          style={{ width: '100%', marginBottom: 4 }}
          defaultValue={item.gender}
          onChange={(gender) => {
            editVariance({ gender })
          }}
        />
      )}

      <EditButton
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        onPressSave={onPressSave}
        resetInputValue={resetInputValue}
      />

      {!isEditing && (
        <Button size="small" onClick={onPressItem} style={{ marginBottom: 4 }}>
          Show Answers
        </Button>
      )}

      {item.status === ARTIFICIAL_TELL_STATUS.INACTIVE && !isEditing && (
        <Button
          block
          danger
          size="small"
          onClick={onPressDelete}
          loading={isDeleting}>
          Delete
        </Button>
      )}
    </Box>
  )
}

const ArtificialTellText = ({
  groupStatus,
  inputValue,
  setInputValue,
  isEditing,
  setIsEditing,
  item,
  onPressSave,
}) => {
  const isEnglish = window.location.pathname.includes('/en/')

  const isActive =
    item.status === ARTIFICIAL_TELL_STATUS.ACTIVE &&
    groupStatus === ARTIFICIAL_TELL_STATUS.ACTIVE

  const onChange = (e) => {
    if (!isEditing) {
      setIsEditing(true)
    }

    setInputValue(e.target.value)
  }

  return (
    <Box flex={1}>
      <Input.TextArea
        autoSize
        bordered={false}
        showCount={false}
        onChange={onChange}
        onPressEnter={onPressSave}
        value={inputValue}
        style={{
          color: isActive ? undefined : 'rgba(0, 0, 0, 0.276)',
          cursor: isEditing ? 'text' : 'default',
        }}
      />
      {isEnglish === false && (
        <Input.TextArea
          disabled
          autoSize
          bordered={false}
          showCount={false}
          value={item.translation}
        />
      )}
    </Box>
  )
}

const LastUpdated = ({ time }) => {
  if (!time) {
    return null
  }

  return (
    <Tooltip
      title={moment(time).format('YYYY-MM-DD HH:mm')}
      placement="bottomLeft">
      <Typography.Text
        style={{ color: theme.colors.placeholder, wordBreak: 'keep-all' }}>
        {timestampToElapsedTime(time)}
      </Typography.Text>
    </Tooltip>
  )
}

export const ArtificialTellItemV2 = ({
  areStatsVisible,
  item,
  groupStats,
  groupStatus,
  setAreStatsVisible,
  isRefreshingVarianceAnalytics,
}) => {
  const language = ReactRedux.useSelector(getLanguage)
  const permissions = ReactRedux.useSelector(getPermissions)
  const [isEditing, setIsEditing] = React.useState(false)
  const [content, setContent] = React.useState(item.content)

  const statsItems = ReactRedux.useSelector((state) => {
    return state.artificialTellsV2.varianceAnalytics[item.id]
  })

  const editVariance = useEditVariance(item)

  const resetInputValue = React.useCallback(() => {
    setContent(item.content)
  }, [item.content])

  const onPressItem = () => {
    const hasEditPermissions = helpers.checkPermission(
      `artificialtells.edit.lang.${langDetectObjectsByType1[language]}`,
      permissions
    )

    if (hasEditPermissions) {
      ModalArtificialTellAnswers.show({ varianceId: item.id })
    }
  }

  const onChangeStatus = (e) => {
    editVariance({ status: e.target.value })
  }

  const setValidationQaStatus = () => {
    editVariance({ status: ARTIFICIAL_TELL_STATUS.VALIDATION })
  }

  const onPressSave = (e) => {
    e?.stopPropagation?.()

    if (content && content !== item.content) {
      editVariance({ content })
    }
  }

  React.useEffect(() => {
    setContent(item.content)
  }, [item.content])

  React.useEffect(() => {
    // Handles browser refresh and tab close when state is dirty. Back navigation is handled with <Prompt /> below.
    const handleBeforeUnload = (event) => {
      if (isEditing) {
        event.preventDefault()

        const message =
          'It looks like you have been editing something.\nIf you leave before saving, your changes will be lost.'

        event.returnValue = message // This is required for Chrome compatibility

        return message
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isEditing])

  const itemStatusMapped =
    {
      [ARTIFICIAL_TELL_STATUS.ACTIVE]: ARTIFICIAL_TELL_STATUS.ACTIVE,
      [ARTIFICIAL_TELL_STATUS.VALIDATION]: ARTIFICIAL_TELL_STATUS.ACTIVE,
      [ARTIFICIAL_TELL_STATUS.INACTIVE]: ARTIFICIAL_TELL_STATUS.INACTIVE,
    }[item.status] ?? item.status

  return (
    <Box overflow="hidden" flexDirection="row" alignItems="center" transparent>
      {/* Handles back navigation when state is dirty. Browser refresh and tab close are handled above with handleBeforeUnload. */}
      <Prompt
        when={isEditing}
        message="It looks like you have been editing something. If you leave before saving, your changes will be lost."
      />
      <Box>
        {item.status === ARTIFICIAL_TELL_STATUS.VALIDATION && (
          <Tooltip title={ARTIFICIAL_TELL_STATUS[item.status]}>
            <Box paddingLeft={12} marginBottom={6}>
              <TagBox
                backgroundColor={
                  colors.artTellStatus[ARTIFICIAL_TELL_STATUS.VALIDATION]
                }
                text="Val."
              />
            </Box>
          </Tooltip>
        )}
        <Box alignItems="center" paddingLeft={12}>
          <Radio.Group
            size="small"
            buttonStyle="solid"
            defaultValue={item.status}
            onChange={onChangeStatus}
            value={itemStatusMapped}>
            <Tooltip
              title={
                itemStatusMapped !== ARTIFICIAL_TELL_STATUS.ACTIVE
                  ? 'Click to set active'
                  : null
              }>
              <Radio.Button
                checked={[
                  ARTIFICIAL_TELL_STATUS.ACTIVE,
                  ARTIFICIAL_TELL_STATUS.VALIDATION,
                ].includes(itemStatusMapped)}
                value={ARTIFICIAL_TELL_STATUS.ACTIVE}
                style={{
                  color:
                    itemStatusMapped === ARTIFICIAL_TELL_STATUS.ACTIVE
                      ? 'white'
                      : 'black',
                  backgroundColor:
                    itemStatusMapped === ARTIFICIAL_TELL_STATUS.ACTIVE
                      ? colors.artTellStatus[ARTIFICIAL_TELL_STATUS.ACTIVE]
                      : 'transparent',
                }}>
                A
              </Radio.Button>
            </Tooltip>
            <Tooltip
              title={
                itemStatusMapped !== ARTIFICIAL_TELL_STATUS.INACTIVE
                  ? 'Click to set inactive'
                  : null
              }>
              <Radio.Button
                value={ARTIFICIAL_TELL_STATUS.INACTIVE}
                style={{
                  minWidth: 22,
                  color:
                    itemStatusMapped === ARTIFICIAL_TELL_STATUS.INACTIVE
                      ? 'white'
                      : 'black',
                  backgroundColor:
                    itemStatusMapped === ARTIFICIAL_TELL_STATUS.INACTIVE
                      ? colors.artTellStatus[ARTIFICIAL_TELL_STATUS.INACTIVE]
                      : 'transparent',
                }}>
                I
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </Box>
      </Box>

      <Box flex={5} flexDirection="row" alignItems="center" paddingLeft={6}>
        <ArtificialTellText
          groupStatus={groupStatus}
          inputValue={content}
          setInputValue={setContent}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          item={item}
          onPressSave={onPressSave}
        />
      </Box>

      <Box alignItems="center" justifyContent="center" />

      <ArtificialTellItemActions
        onPressSave={onPressSave}
        onPressItem={onPressItem}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        item={item}
        resetInputValue={resetInputValue}
      />

      {areStatsVisible && Boolean(statsItems) ? (
        <Box paddingLeft={8}>
          <ArtificialTellStats
            groupStats={groupStats}
            items={statsItems}
            type="micro"
          />
        </Box>
      ) : (
        <Box width={160} alignItems="center">
          <Button
            loading={isRefreshingVarianceAnalytics}
            size="small"
            onClick={() => {
              setAreStatsVisible(true)
            }}
            style={{ marginBottom: 12 }}>
            Show stats
          </Button>
          {item.status === ARTIFICIAL_TELL_STATUS.INACTIVE && (
            <Button
              loading={isRefreshingVarianceAnalytics}
              size="small"
              onClick={setValidationQaStatus}
              style={{ fontSize: 10 }}>
              Set to Validation
            </Button>
          )}
        </Box>
      )}

      <Box alignItems="center" paddingLeft={12} width={40}>
        <LastUpdated time={item.lastUpdatedAt} />
      </Box>
    </Box>
  )
}
