import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import { configureCore } from '@tellonym/core/config'
import { events } from '@tellonym/core/events'
import { safeEffects } from '@tellonym/core/sagas'
import { LOGIN_SUCCESS } from '@tellonym/core/user/types'
import { delay, put, select } from 'redux-saga/effects'
import { config } from '../../config'
import { refreshPermissions, toggleSidemenu } from './actions'
import { getCustomApiHost, getIsSidemenuShown } from './selectors'
import * as t from './types'

const getPermissions = function* () {
  const isLoggedIn = yield select(getIsLoggedIn)

  if (isLoggedIn) {
    yield put(refreshPermissions())
  }
}

const loadCustomApiSettings = function* () {
  const customApiHost = yield select(getCustomApiHost)
  if (typeof customApiHost === 'string' && customApiHost !== '') {
    // eslint-disable-next-line no-console
    console.log('Setting api host to saved customApiHost', customApiHost)
    configureCore({ api: { host: customApiHost } })
  } else {
    // reset to default
    console.log('Resetting api host to default', config.api.host)
    configureCore(config)
  }
}

const hideSidemenuMobile = function* () {
  const isSidemenuShown = yield select(getIsSidemenuShown)

  if (isSidemenuShown && window.tnym.isDesktop() === false) {
    yield put(toggleSidemenu())
  }
}

const setupTheme = function* (action) {
  // User is switching to light mode so we need to reload to get rid of the dark.css
  if (action.type === t.SET_IS_DARK_MODE_ENABLED && action.payload === false) {
    yield delay(300)
    window.location.reload()
  }
}

export const eventHandler = {
  [events.LOAD]: [
    setupTheme,
    loadCustomApiSettings,
    getPermissions,
    hideSidemenuMobile,
  ],
}

export const actionHandler = {
  [LOGIN_SUCCESS]: [safeEffects.takeLatest, getPermissions],
  [t.SET_CUSTOM_API_HOST]: [safeEffects.takeLatest, loadCustomApiSettings],
  [t.SET_IS_DARK_MODE_ENABLED]: [safeEffects.takeLatest, setupTheme],
}
