import { colors } from '@tellonym/core/common/colorSystem'
import { intHash } from '@tellonym/core/helpers'
import { PERFORMANCE_TYPE } from '@tellonym/enums/lib/TellArtificial'
import {
  Button,
  Card,
  Divider,
  Empty,
  Input,
  List,
  Select,
  Tooltip,
  message,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { Avatar, Box, ScrollView, Text, hooks } from '../../common'
import { colors as avatarColors } from '../../common/components/Avatar'
import { TagBox } from '../../common/components/TagBox'
import { getNumbersFromEnums, languageEnumAsString } from '../../common/helpers'
import { useProfileSettingsQuery } from '../../profile/queries'
import { useAddPerformanceCheckInMutation } from '../queries'

const styles = {
  divider: {
    marginTop: 8,
    marginBottom: 0,
  },
}

const performanceTypeConfig = {
  [PERFORMANCE_TYPE.BAD]: { text: 'Bad', color: colors.red[5] },
  [PERFORMANCE_TYPE.BAD_AR]: { text: 'Bad: Answer Rate', color: colors.red[5] },
  [PERFORMANCE_TYPE.BAD_ANSWERS]: {
    text: 'Bad: Answers',
    color: colors.red[5],
  },
  [PERFORMANCE_TYPE.MIXED]: { text: 'Good', color: colors.yellow[1] },
  [PERFORMANCE_TYPE.MIXED_GOOD_LENGTH]: {
    text: 'Good, Great Length',
    color: colors.yellow[1],
  },
  [PERFORMANCE_TYPE.MIXED_GOOD_SHARE]: {
    text: 'Good, Great Share',
    color: colors.yellow[1],
  },
  [PERFORMANCE_TYPE.MIXED_GOOD_ANSWERS]: {
    text: 'Good, Great Answers',
    color: colors.yellow[1],
  },
  [PERFORMANCE_TYPE.GREAT]: { text: 'Great', color: colors.green[8] },
  [PERFORMANCE_TYPE.GREAT_BAD_SHARE]: {
    text: 'Great, Bad Share',
    color: colors.green[8],
  },
  [PERFORMANCE_TYPE.GREAT_OPPORTUNITY]: {
    text: 'Great, Opportunity',
    color: colors.green[8],
  },
}

const performanceTypeOptions = getNumbersFromEnums(PERFORMANCE_TYPE).map(
  (key) => ({
    value: key,
    label: performanceTypeConfig[key]?.text,
  })
)

const ListItem = ({ item }) => {
  const { data } = useProfileSettingsQuery({ userId: item.creatorUserId })

  return (
    <>
      <Tooltip title={item.kpiText} placement="right">
        <Box transparent flexDirection="row" marginTop={8} alignItems="center">
          <Box marginRight={6} marginTop={2}>
            <TagBox
              note
              width={22.5}
              text={languageEnumAsString[item.language]?.toUpperCase()}
              backgroundColor={
                avatarColors[
                  intHash(languageEnumAsString[item.language]) %
                    avatarColors.length
                ]
              }
            />
          </Box>
          <Box marginRight={6} marginTop={2}>
            <TagBox
              note
              text={performanceTypeConfig[item.performanceType]?.text ?? 'None'}
              backgroundColor={
                performanceTypeConfig[item.performanceType]?.color ??
                colors.grey[5]
              }
            />
          </Box>
          <Avatar
            size={20}
            user={{
              username: data?.username ?? '',
              avatarFileName: data?.avatars?.find((a) => a.position === 0)
                ?.avatarFileName,
            }}
            style={{ marginRight: 6 }}
          />
          <Text
            type="note"
            semibold
            style={{ flex: 1 }}>{`${item.creatorUsername}`}</Text>
          <Text type="micro" color={colors.grey[7]}>
            {dayjs(item.createdAt).fromNow()}
          </Text>
        </Box>

        <Text type="note" style={{ marginTop: 6 }}>
          {item.content}
        </Text>
      </Tooltip>
      <Divider style={styles.divider} />
    </>
  )
}

/**
 * Either groupId or topicId is defined
 */
export const PerformanceCheckIns = ({
  data,
  groupId,
  topicId,
  language,
  isLoading,
}) => {
  const inputRef = React.useRef(null)

  const [content, setContent] = React.useState('')
  const [selectedPerformanceType, setSelectedPerformanceType] =
    React.useState(undefined)

  const { mutate, isLoading: isSubmitting } = useAddPerformanceCheckInMutation({
    groupId,
    topicId,
  })

  const onChangePerformanceType = (value) => {
    setSelectedPerformanceType(value)
  }

  const onSubmitNewEntry = () => {
    if (!selectedPerformanceType) {
      message.warn('Please select a performance type')

      return
    }

    mutate({
      groupId,
      topicId,
      language,
      content,
      performanceType: selectedPerformanceType,
    })

    setContent('')
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit: onSubmitNewEntry,
    hasActionKey: true,
  })

  hooks.useEscapeKey({
    inputRef,
    onPress: () => {
      inputRef.current.blur()
    },
  })

  return (
    <Box
      width={400}
      transparent
      justifyContent="space-between"
      marginRight={12}>
      <Card
        size="small"
        bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
        style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{ paddingTop: 4, paddingBottom: 12 }}
          style={{ height: 150 }}>
          <List
            loading={isLoading}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No performance check-ins yet."
                  style={{ marginTop: 0, marginBottom: 0 }}
                />
              ),
            }}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => <ListItem key={item.id} item={item} />}
          />
        </ScrollView>
      </Card>
      <Input.Group compact style={{ paddingTop: 8 }}>
        <Select
          className="select-after"
          options={performanceTypeOptions}
          onChange={onChangePerformanceType}
          value={selectedPerformanceType}
          dropdownMatchSelectWidth={false}
          style={{ width: 120, fontSize: 10 }}
        />

        <Input.TextArea
          ref={inputRef}
          size="small"
          autoSize={{ maxRows: 1 }}
          style={{ width: 'calc(100% - 120px - 45px)' }}
          defaultValue={content}
          onChange={(e) => setContent(e.target.value)}
          value={content}
          placeholder="Performance check-in entry..."
        />

        <Button
          type="bordered"
          onClick={onSubmitNewEntry}
          loading={isSubmitting}
          style={{ width: 45, padding: 4 }}>
          Add
        </Button>
      </Input.Group>
    </Box>
  )
}
