import { shortenNumber } from '@tellonym/core/helpers'
import {
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_STATUS,
} from '@tellonym/enums/lib/Tell'
import { Tooltip } from 'antd'
import React, { useMemo } from 'react'
import * as ReactRedux from 'react-redux'
import {
  TableEndlessScroll,
  View,
  _,
  moment,
  styleSheets,
  theme,
} from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { valueToPercentage } from '../../statistics/services'
import { getHeaderMode } from '../selectorsV2'
import { SharedColumns, useColumnSearchProps } from './SharedColumns'

const RESTRICTION_TYPE = {
  NONE: 0,
  DURATION: 1,
  START: 2,
  END: 3,
}

const getRestrictionType = (validPeriod) => {
  const hasStart = Boolean(validPeriod.startDate)
  const hasEnd = Boolean(validPeriod.endDate)
  const hasBoth = hasStart && hasEnd

  if (hasBoth) {
    return RESTRICTION_TYPE.DURATION
  }

  if (hasStart) {
    return RESTRICTION_TYPE.START
  }

  if (hasEnd) {
    return RESTRICTION_TYPE.END
  }

  return RESTRICTION_TYPE.NONE
}

const GroupAmounts = ({ activeValues, inactiveValues }) => {
  return (
    <View style={styleSheets.center}>{`${shortenNumber(activeValues.total)} ${
      typeof inactiveValues?.total === 'number'
        ? `(${shortenNumber(inactiveValues?.total)})`
        : ''
    }`}</View>
  )
}

export const ArtificialTellTopicsTable = (props) => {
  const searchProps = useColumnSearchProps('name')

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const columns = useMemo(
    () =>
      props.columns ?? [
        {
          title: '',
          children: [
            SharedColumns.id,
            SharedColumns.status,
            {
              title: 'Topic Name',
              dataIndex: 'id',
              key: 'topicName',
              width: '30%',
              render: (id, topic) => (
                <Tooltip title={topic.description}>
                  <View>{topic.name}</View>
                </Tooltip>
              ),
              sorter: (a, b) => {
                const textA = a.name
                const textB = b.name

                if (textA < textB) {
                  return -1
                }

                if (textA > textB) {
                  return 1
                }

                return 0
              },
              ...searchProps,
            },
            {
              title: (
                <Tooltip title="Groups with colored background are exclusive">
                  <span>Content Group</span>
                </Tooltip>
              ),
              dataIndex: 'id',
              key: 'contentGroup',
              width: 100,
              filterMultiple: false,
              filters: [
                {
                  text: 'Has Content Group',
                  value: 1,
                },
                {
                  text: 'Has No Content Group',
                  value: 0,
                },
              ],
              onFilter: (value, group) => {
                switch (value) {
                  case 1:
                    return Boolean(group.contentGroup)
                  case 0:
                    return !Boolean(group.contentGroup)
                  default:
                    return true
                }
              },
              onHeaderCell: () => ({
                onClick: () => {
                  props.setTableState((tableState) => ({
                    ...tableState,
                    filters: {
                      ...tableState.filters,
                      contentGroup: Array.isArray(
                        tableState.filters.contentGroup
                      )
                        ? undefined
                        : [1],
                    },
                  }))
                },
              }),
              render: (id, topic) => {
                return (
                  <TagBox
                    isLabel
                    backgroundColor={
                      topic.isContentGroupExclusive
                        ? theme.colors.lightBlue
                        : 'transparent'
                    }
                    color={topic.isContentGroupExclusive ? 'white' : 'black'}
                    text={topic.contentGroup}
                  />
                )
              },
            },
            {
              title: 'Groups Intro',
              dataIndex: 'id',
              key: 'groupsIntro',
              width: 100,
              filterMultiple: false,
              filters: [
                {
                  text: 'Has active groups',
                  value: 1,
                },
                {
                  text: 'Has inactive groups',
                  value: 2,
                },
              ],
              onFilter: (value, topic) => {
                switch (value) {
                  case 1:
                    return (
                      topic.activeGroupsCount[
                        ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO
                      ].total > 0
                    )
                  case 2:
                    return (
                      topic.inactiveGroupsCount?.[
                        ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO
                      ]?.total > 0
                    )
                  default:
                    return true
                }
              },
              sorter: (a, b) =>
                a.activeGroupsCount[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                  .totals -
                b.activeGroupsCount[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                  .totals,
              render: (id, topic) => {
                if (topic.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
                  return null
                }

                const activeValues =
                  topic.activeGroupsCount[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]

                const inactiveValues =
                  topic.inactiveGroupsCount?.[
                    ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO
                  ]

                return (
                  <GroupAmounts
                    activeValues={activeValues}
                    inactiveValues={inactiveValues}
                  />
                )
              },
            },
            {
              title: 'Groups Deep',
              dataIndex: 'id',
              key: 'groupsDeep',
              width: 100,
              filterMultiple: false,
              filters: [
                {
                  text: 'Has active groups',
                  value: 1,
                },
                {
                  text: 'Has inactive groups',
                  value: 2,
                },
              ],
              onFilter: (value, topic) => {
                switch (value) {
                  case 1:
                    return (
                      topic.activeGroupsCount[
                        ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP
                      ].total > 0
                    )
                  case 2:
                    return (
                      topic.inactiveGroupsCount?.[
                        ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP
                      ]?.total > 0
                    )
                  default:
                    return true
                }
              },
              sorter: (a, b) =>
                a.activeGroupsCount[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
                  .totals -
                b.activeGroupsCount[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].totals,
              render: (id, topic) => {
                if (topic.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
                  return null
                }

                const activeValues =
                  topic.activeGroupsCount[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]

                const inactiveValues =
                  topic.inactiveGroupsCount?.[
                    ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP
                  ]

                return (
                  <GroupAmounts
                    activeValues={activeValues}
                    inactiveValues={inactiveValues}
                  />
                )
              },
            },
          ],
        },
        {
          title: 'Intro',
          children: [
            {
              title: 'Total Sent',
              dataIndex: 'id',
              key: 'totalSentIntro',
              width: 80,
              sorter: (a, b) =>
                a.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].totalSent -
                b.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].totalSent,
              filterMultiple: false,
              filters: [
                {
                  text: 'Total Sent > 0',
                  value: 1,
                },
                {
                  text: 'Total Sent > 100',
                  value: 2,
                },
                {
                  text: 'Total Sent > 1000',
                  value: 3,
                },
              ],
              onFilter: (value, topic) => {
                switch (value) {
                  case 1:
                    return (
                      topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                        .totalSent > 0
                    )
                  case 2:
                    return (
                      topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                        .totalSent > 100
                    )
                  case 3:
                    return (
                      topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                        .totalSent > 1000
                    )
                  default:
                    return true
                }
              },
              render: (id, topic) => (
                <View style={styleSheets.center}>
                  {shortenNumber(
                    topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                      .totalSent,
                    false,
                    999
                  )}
                </View>
              ),
            },
            {
              title: 'Answer Rate',
              dataIndex: 'id',
              key: 'answerRateIntro',
              width: 80,
              sorter: (a, b) =>
                a.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].answerRate -
                b.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].answerRate,
              render: (id, topic) => (
                <View style={styleSheets.center}>
                  {valueToPercentage(
                    topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                      .answerRate,
                    1
                  ).replace('.0', '')}
                </View>
              ),
            },
            {
              title: 'Median Length',
              dataIndex: 'id',
              key: 'medianLengthIntro',
              width: 80,
              sorter: (a, b) =>
                a.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].p50Length -
                b.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].p50Length,
              render: (id, topic) => (
                <View style={styleSheets.center}>
                  {shortenNumber(
                    topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                      .p50Length,
                    false,
                    999
                  )}
                </View>
              ),
            },
            {
              title: 'Share Rate',
              dataIndex: 'id',
              key: 'shareRateIntro',
              width: 80,
              sorter: (a, b) =>
                a.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].shareRate -
                b.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO].shareRate,
              render: (id, topic) => (
                <View style={styleSheets.center}>
                  {valueToPercentage(
                    topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
                      .shareRate,
                    1
                  ).replace('.0', '')}
                </View>
              ),
            },
          ],
        },
        {
          title: 'Deep',
          children: [
            {
              title: 'Total Sent',
              dataIndex: 'id',
              key: 'totalSentDeep',
              width: 80,
              sorter: (a, b) =>
                a.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].totalSent -
                b.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].totalSent,
              filterMultiple: false,
              filters: [
                {
                  text: 'Total Sent > 0',
                  value: 1,
                },
                {
                  text: 'Total Sent > 100',
                  value: 2,
                },
                {
                  text: 'Total Sent > 1000',
                  value: 3,
                },
              ],
              onFilter: (value, topic) => {
                switch (value) {
                  case 1:
                    return (
                      topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
                        .totalSent > 0
                    )
                  case 2:
                    return (
                      topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
                        .totalSent > 100
                    )
                  case 3:
                    return (
                      topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
                        .totalSent > 1000
                    )
                  default:
                    return true
                }
              },
              render: (id, topic) => (
                <View style={styleSheets.center}>
                  {shortenNumber(
                    topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
                      .totalSent,
                    false,
                    999
                  )}
                </View>
              ),
            },
            {
              title: 'Answer Rate',
              dataIndex: 'id',
              key: 'answerRateDeep',
              width: 80,
              sorter: (a, b) =>
                a.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].answerRate -
                b.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP].answerRate,
              render: (id, topic) => (
                <View style={styleSheets.center}>
                  {valueToPercentage(
                    topic.analytics[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
                      .answerRate,
                    1
                  ).replace('.0', '')}
                </View>
              ),
            },
          ],
        },
        {
          title: '',
          children: [
            {
              title: 'Time Limit',
              dataIndex: 'id',
              key: 'validPeriod',
              width: 120,
              filterMultiple: false,
              filters: [
                {
                  text: 'Has Limit',
                  value: 1,
                },
              ],
              onFilter: (value, topic) => {
                return value === 1
                  ? topic.validPeriod.startDate || topic.validPeriod.endDate
                  : true
              },
              onHeaderCell: () => ({
                onClick: () => {
                  props.setTableState((tableState) => ({
                    ...tableState,
                    filters: {
                      ...tableState.filters,
                      validPeriod: Array.isArray(tableState.filters.validPeriod)
                        ? undefined
                        : [1],
                    },
                  }))
                },
              }),
              render: (id, topic) => {
                const type = getRestrictionType(topic.validPeriod)
                const isRepeated = topic.validPeriod.repeatedYearly

                const dateStart = topic.validPeriod.startDate
                  ? moment(topic.validPeriod.startDate).format('DD.MM')
                  : ''
                const dateEnd = topic.validPeriod.endDate
                  ? moment(topic.validPeriod.endDate).format(
                      isRepeated ? 'DD.MM' : 'DD.MM.YY'
                    )
                  : ''

                switch (type) {
                  case RESTRICTION_TYPE.DURATION:
                    return `${dateStart} - ${dateEnd}`
                  case RESTRICTION_TYPE.START:
                    return `from ${dateStart}`
                  case RESTRICTION_TYPE.END:
                    return `till ${dateEnd}`
                  case RESTRICTION_TYPE.NONE:
                  default:
                    return ''
                }
              },
            },
            {
              ...SharedColumns.lastUpdatedAt,
              defaultSortOrder: 'descend',
            },
          ],
        },
      ],
    [props.columns, props.interestsList]
  )

  const onRow = React.useCallback(
    (topic) => {
      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/topic/${topic.id}${
          headerMode ? `?headerMode=${headerMode}` : ''
        }`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language, headerMode]
  )

  return (
    <TableEndlessScroll
      {...props}
      bordered
      hasSmallRows
      hasZebraRows
      columns={columns}
      onRow={onRow}
    />
  )
}
