export const ADD_ACCOUNT = 'profile/ADD_ACCOUNT'

export const CHANGE_APP_TRANSLATION = 'profile/CHANGE_APP_TRANSLATION'
export const CHANGE_APP_TRANSLATION_ERROR = 'profile/CHANGE_APP_TRANSLATION_ERROR' // prettier-ignore
export const CHANGE_APP_TRANSLATION_SUCCESS = 'profile/CHANGE_APP_TRANSLATION_SUCCESS' // prettier-ignore

export const CHANGE_AVATAR = 'profile/CHANGE_AVATAR'
export const CHANGE_AVATAR_ERROR = 'profile/CHANGE_AVATAR_ERROR'
export const CHANGE_AVATAR_SUCCESS = 'profile/CHANGE_AVATAR_SUCCESS'

export const CHANGE_EMAIL = 'profile/CHANGE_EMAIL'
export const CHANGE_EMAIL_ERROR = 'profile/CHANGE_EMAIL_ERROR'
export const CHANGE_EMAIL_SUCCESS = 'profile/CHANGE_EMAIL_SUCCESS'

export const CHANGE_PASSWORD = 'profile/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_ERROR = 'profile/CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_SUCCESS = 'profile/CHANGE_PASSWORD_SUCCESS'

export const CHANGE_PUSH_SETTINGS = 'profile/CHANGE_PUSH_SETTINGS'
export const CHANGE_PUSH_SETTINGS_ERROR = 'profile/CHANGE_PUSH_SETTINGS_ERROR'
export const CHANGE_PUSH_SETTINGS_SUCCESS = 'profile/CHANGE_PUSH_SETTINGS_SUCCESS' // prettier-ignore

export const CHANGE_SAFETY_LEVEL = 'profile/CHANGE_SAFETY_LEVEL'
export const CHANGE_SAFETY_LEVEL_ERROR = 'profile/CHANGE_SAFETY_LEVEL_ERROR'
export const CHANGE_SAFETY_LEVEL_SUCCESS = 'profile/CHANGE_SAFETY_LEVEL_SUCCESS'

export const CHANGE_SETTINGS = 'profile/CHANGE_SETTINGS'
export const CHANGE_SETTINGS_ERROR = 'profile/CHANGE_SETTINGS_ERROR'
export const CHANGE_SETTINGS_SUCCESS = 'profile/CHANGE_SETTINGS_SUCCESS'

export const CHANGE_STATUS_EMOJI = 'profile/CHANGE_STATUS_EMOJI'
export const CHANGE_STATUS_EMOJI_SUCCESS = 'profile/CHANGE_STATUS_EMOJI_SUCCESS'
export const CHANGE_STATUS_EMOJI_ERROR = 'profile/CHANGE_STATUS_EMOJI_ERROR'

export const CHANGE_TELLS_PREFERENCES = 'profile/CHANGE_TELLS_PREFERENCES'
export const CHANGE_TELLS_PREFERENCES_SUCCESS = 'profile/CHANGE_TELLS_PREFERENCES_SUCCESS' // prettier-ignore
export const CHANGE_TELLS_PREFERENCES_ERROR = 'profile/CHANGE_TELLS_PREFERENCES_ERROR' // prettier-ignore

export const CHANGE_STATE = 'profile/CHANGE_STATE'

export const CHANGE_TELL = 'profile/CHANGE_TELL'

export const CHECK_SAFETY_CODE = 'profile/CHECK_SAFETY_CODE'
export const CHECK_SAFETY_CODE_ERROR = 'profile/CHECK_SAFETY_CODE_ERROR'
export const CHECK_SAFETY_CODE_SUCCESS = 'profile/CHECK_SAFETY_CODE_SUCCESS'

export const CONNECT_APPLE = 'profile/CONNECT_APPLE'
export const CONNECT_APPLE_ERROR = 'profile/CONNECT_APPLE_ERROR'
export const CONNECT_APPLE_SUCCESS = 'profile/CONNECT_APPLE_SUCCESS'

export const CONNECT_EMAIL = 'profile/CONNECT_EMAIL'
export const CONNECT_EMAIL_ERROR = 'profile/CONNECT_EMAIL_ERROR'
export const CONNECT_EMAIL_SUCCESS = 'profile/CONNECT_EMAIL_SUCCESS'

export const CONNECT_GOOGLE = 'profile/CONNECT_GOOGLE'
export const CONNECT_GOOGLE_ERROR = 'profile/CONNECT_GOOGLEL_ERROR'
export const CONNECT_GOOGLE_SUCCESS = 'profile/CONNECT_GOOGLE_SUCCESS'

export const CONNECT_LOCATION = 'profile/CONNECT_LOCATION'

export const COPY = 'profile/COPY'

export const CONFIRM_USER_WARNING = 'profile/CONFIRM_USER_WARNING'
export const CONFIRM_USER_WARNING_ERROR = 'profile/CONFIRM_USER_WARNING_ERROR' // prettier-ignore
export const CONFIRM_USER_WARNING_SUCCESS = 'profile/CONFIRM_USER_WARNING_SUCCESS' // prettier-ignore

export const DISCONNECT_INSTAGRAM = 'profile/DISCONNECT_INSTAGRAM'
export const DISCONNECT_INSTAGRAM_ERROR = 'profile/DISCONNECT_INSTAGRAM_ERROR'
export const DISCONNECT_INSTAGRAM_SUCCESS = 'profile/DISCONNECT_INSTAGRAM_SUCCESS' // prettier-ignore

export const EDIT_INTERESTS = 'profile/EDIT_INTERESTS'
export const EDIT_INTERESTS_ERROR = 'profile/EDIT_INTERESTS_ERROR'
export const EDIT_INTERESTS_SUCCESS = 'profile/EDIT_INTERESTS_SUCCESS'

export const SUBSCRIBE_TO_PROFILE = 'profile/SUBSCRIBE_TO_PROFILE' // prettier-ignore

export const FETCH_ANSWERS = 'profile/FETCH_ANSWERS'
export const FETCH_ANSWERS_ERROR = 'profile/FETCH_ANSWERS_ERROR'
export const FETCH_ANSWERS_SUCCESS = 'profile/FETCH_ANSWERS_SUCCESS'

export const FETCH_MAIN_LANGUAGE_SETTINGS = 'profile/FETCH_MAIN_LANGUAGE_SETTINGS' // prettier-ignore
export const FETCH_MAIN_LANGUAGE_SETTINGS_ERROR = 'profile/FETCH_MAIN_LANGUAGE_SETTINGS_ERROR' // prettier-ignore
export const FETCH_MAIN_LANGUAGE_SETTINGS_SUCCESS = 'profile/FETCH_MAIN_LANGUAGE_SETTINGS_SUCCESS' // prettier-ignore

export const INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS = 'profile/INCREMENT_STATUS_EMOJI_PROGRESS_CHALLENGE' // prettier-ignore
export const INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS_SUCCESS = 'profile/INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS_SUCCESS' // prettier-ignore
export const INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS_ERROR = 'profile/INCREMENT_STATUS_EMOJI_CHALLENGE_PROGRESS_ERROR' // prettier-ignore

export const LOGOUT = 'profile/LOGOUT'
export const LOGOUT_ERROR = 'profile/LOGOUT_ERROR'
export const LOGOUT_SUCCESS = 'profile/LOGOUT_SUCCESS'

export const LOG_SOCIAL_LINK_PRESS = 'profile/LOG_SOCIAL_LINK_PRESS'
export const LOG_SOCIAL_LINK_PRESS_ERROR = 'profile/LOG_SOCIAL_LINK_PRESS_ERROR'
export const LOG_SOCIAL_LINK_PRESS_SUCCESS = 'profile/LOG_SOCIAL_LINK_PRESS_SUCCESS' // prettier-ignore

export const REFRESH = 'profile/REFRESH'
export const REFRESH_ERROR = 'profile/REFRESH_ERROR'
export const REFRESH_SUCCESS = 'profile/REFRESH_SUCCESS'

export const REFRESH_ANSWERS = 'profile/REFRESH_ANSWERS'
export const REFRESH_ANSWERS_ERROR = 'profile/REFRESH_ANSWERS_ERROR'
export const REFRESH_ANSWERS_SUCCESS = 'profile/REFRESH_ANSWERS_SUCCESS'

export const REFRESH_INTERESTS = 'profile/REFRESH_INTERESTS'
export const REFRESH_INTERESTS_ERROR = 'profile/REFRESH_INTERESTS_ERROR'
export const REFRESH_INTERESTS_SUCCESS = 'profile/REFRESH_INTERESTS_SUCCESS'

export const REFRESH_SETTINGS = 'profile/REFRESH_SETTINGS'
export const REFRESH_SETTINGS_ERROR = 'profile/REFRESH_SETTINGS_ERROR'
export const REFRESH_SETTINGS_SUCCESS = 'profile/REFRESH_SETTINGS_SUCCESS'

export const REFRESH_ACHIEVABLE_EMOJIS = 'profile/REFRESH_ACHIEVABLE_EMOJIS'
export const REFRESH_ACHIEVABLE_EMOJIS_ERROR = 'profile/REFRESH_ACHIEVABLE_EMOJIS_ERROR' // prettier-ignore
export const REFRESH_ACHIEVABLE_EMOJIS_SUCCESS = 'profile/REFRESH_ACHIEVABLE_EMOJIS_SUCCESS' // prettier-ignore

export const REFRESH_STATUS_EMOJIS = 'profile/REFRESH_STATUS_EMOJIS'
export const REFRESH_STATUS_EMOJIS_SUCCESS = 'profile/REFRESH_STATUS_EMOJIS_SUCCESS' // prettier-ignore
export const REFRESH_STATUS_EMOJIS_ERROR = 'profile/REFRESH_STATUS_EMOJIS_ERROR'

export const REFRESH_TELLS_PREFERENCES = 'profile/REFRESH_TELLS_PREFERENCES'
export const REFRESH_TELLS_PREFERENCES_SUCCESS = 'profile/REFRESH_TELLS_PREFERENCES_SUCCESS' // prettier-ignore
export const REFRESH_TELLS_PREFERENCES_ERROR = 'profile/REFRESH_TELLS_PREFERENCES_ERROR' // prettier-ignore

export const REMOVE_ACCOUNT = 'profile/REMOVE_ACCOUNT'
export const REMOVE_ACCOUNT_ERROR = 'profile/REMOVE_ACCOUNT_ERROR'
export const REMOVE_ACCOUNT_SUCCESS = 'profile/REMOVE_ACCOUNT_SUCCESS'

export const REMOVE_AVATAR = 'profile/REMOVE_AVATAR'
export const REMOVE_AVATAR_ERROR = 'profile/REMOVE_AVATAR_ERROR'
export const REMOVE_AVATAR_SUCCESS = 'profile/REMOVE_AVATAR_SUCCESS'

export const CHANGE_AVATAR_POSITION = 'profile/CHANGE_AVATAR_POSITION'
export const CHANGE_AVATAR_POSITION_ERROR = 'profile/CHANGE_AVATAR_POSITION_ERROR' // prettier-ignore
export const CHANGE_AVATAR_POSITION_SUCCESS = 'profile/CHANGE_AVATAR_POSITION_SUCCESS' // prettier-ignore

export const REMOVE_SAFETY_CODE = 'profile/REMOVE_SAFETY_CODE'
export const REMOVE_SAFETY_CODE_ERROR = 'profile/REMOVE_SAFETY_CODE_ERROR'
export const REMOVE_SAFETY_CODE_SUCCESS = 'profile/REMOVE_SAFETY_CODE_SUCCESS'

export const SAVE_AVATAR = 'profile/SAVE_AVATAR'
export const SAVE_AVATAR_ERROR = 'profile/SAVE_AVATAR_ERROR'
export const SAVE_AVATAR_SUCCESS = 'profile/SAVE_AVATAR_SUCCESS'

export const UPLOAD_AVATAR = 'profile/UPLOAD_AVATAR'
export const UPLOAD_AVATAR_ERROR = 'profile/UPLOAD_AVATAR_ERROR'
export const UPLOAD_AVATAR_SUCCESS = 'profile/UPLOAD_AVATAR_SUCCESS'

export const SEND_SAFETY_CODE_MAIL = 'profile/SEND_SAFETY_CODE_MAIL'
export const SEND_SAFETY_CODE_MAIL_ERROR = 'profile/SEND_SAFETY_CODE_MAIL_ERROR'
export const SEND_SAFETY_CODE_MAIL_SUCCESS = 'profile/SEND_SAFETY_CODE_MAIL_SUCCESS' // prettier-ignore

export const SET_PHONE = 'profile/SET_PHONE'
export const SET_PHONE_ERROR = 'profile/SET_PHONE_ERROR'
export const SET_PHONE_SUCCESS = 'profile/SET_PHONE_SUCCESS'

export const SET_SAFETY_CODE = 'profile/SET_SAFETY_CODE'
export const SET_SAFETY_CODE_ERROR = 'profile/SET_SAFETY_CODE_ERROR'
export const SET_SAFETY_CODE_SUCCESS = 'profile/SET_SAFETY_CODE_SUCCESS'

export const SET_STATUS_EMOJI_IS_OWNED = 'profile/SET_STATUS_EMOJI_IS_OWNED'

export const TOGGLE_PUSH_NOTIFICATION = 'profile/TOGGLE_PUSH_NOTIFICATION'

export const UNLOCK_STATUS_EMOJI = 'profile/UNLOCK_STATUS_EMOJI'
export const UNLOCK_STATUS_EMOJI_SUCCESS = 'profile/UNLOCK_STATUS_EMOJI_SUCCESS'
export const UNLOCK_STATUS_EMOJI_ERROR = 'profile/UNLOCK_STATUS_EMOJI_ERROR'

export const WRITE_TELL = 'profile/WRITE_TELL'
export const WRITE_TELL_ERROR = 'profile/WRITE_TELL_ERROR'
export const WRITE_TELL_SUCCESS = 'profile/WRITE_TELL_SUCCESS'

export const SET_COMPLETE_PROFILE_SLIDER_COMPLETE = 'profile/SET_COMPLETE_PROFILE_SLIDER_COMPLETE' // prettier-ignore
export const SET_COMPLETE_PROFILE_SLIDER_HIDE = 'profile/SET_COMPLETE_PROFILE_SLIDER_HIDE' //prettier-ignore

export const UPLOAD_ACCOUNT_VERIFICATION_SELFIE = 'profile/UPLOAD_ACCOUNT_VERIFICATION_SELFIE' // prettier-ignore
export const UPLOAD_ACCOUNT_VERIFICATION_SELFIE_ERROR = 'profile/UPLOAD_ACCOUNT_VERIFICATION_SELFIE_ERROR' // prettier-ignore
export const UPLOAD_ACCOUNT_VERIFICATION_SELFIE_SUCCESS = 'profile/UPLOAD_ACCOUNT_VERIFICATION_SELFIE_SUCCESS' // prettier-ignore

export const VERIFY_ACCOUNT = 'profile/VERIFY_ACCOUNT'
export const VERIFY_ACCOUNT_ERROR = 'profile/VERIFY_ACCOUNT_ERROR'
export const VERIFY_ACCOUNT_SUCCESS = 'profile/VERIFY_ACCOUNT_SUCCESS'

export const SET_VERIFICATION_BANNER_STATUS = 'profile/SET_VERIFICATION_BANNER_STATUS' //prettier-ignore

export const SWITCH_ACCOUNT = 'app/SWITCH_ACCOUNT'

export const SET_IS_MAINTENANCE_MODE = 'app/SET_IS_MAINTENANCE_MODE'
