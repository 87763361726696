import {
  CaretDownOutlined,
  CaretUpOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { ProCard, StatisticCard } from '@ant-design/pro-components'
import { colors } from '@tellonym/core/common/colorSystem'
import { CountryPhoneCodes } from '@tellonym/core/constants/CountryPhoneCodes'
import { capitalize, formatNumber } from '@tellonym/core/helpers'
import { copyToClipboard } from '@tellonym/core/share/actions'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_USER_GROUP_TYPE,
} from '@tellonym/enums/lib/Tell'
import {
  Badge,
  Grid,
  Progress,
  Space,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import qs from 'qs'
import * as R from 'ramda'
import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  history,
  hooks,
  ScrollView,
  styleSheets,
  Text,
} from '../../common'
import { setLanguage } from '../actionsV2'
import { useAvailableLanguages } from '../hooks/useAvailableLanguages'
import { useDashboardQuery } from '../queries'
import { getLanguage } from '../selectorsV2'

const { Statistic } = StatisticCard

const userGroupTypeShortnameMap = {
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW]: 'LOW',
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.MEDIUM]: 'MEDIUM',
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH]: 'HIGH',
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL]: 'ALL',
  ALL: 'ALL',
}

const userGroupTypeLabelMap = {
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW
    ].toLowerCase(),
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.MEDIUM]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.MEDIUM
    ].toLowerCase(),
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH
    ].toLowerCase(),
  [ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL]:
    ARTIFICIAL_TELL_USER_GROUP_TYPE[
      ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL
    ].toLowerCase(),
  ALL: 'all',
}

const groupTypeColorMap = {
  ALL: 'geekblue',
  [ARTIFICIAL_TELL_GROUP_TYPES.OPEN]: 'blue',
  [ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]: 'volcano',
  [ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]: 'green',
  [ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT]: 'gold',
  [ARTIFICIAL_TELL_GROUP_TYPES.SHARE]: 'purple',
}

const CopyableTitle = ({ title, id }) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <span
      onClick={() => dispatch(copyToClipboard(id))}
      style={{ cursor: 'pointer' }}>
      {title}
    </span>
  )
}

const InfoTooltip = ({ data, description }) => {
  return (
    <Tooltip
      title={
        <Space direction="vertical">
          {Boolean(description) && <span>{description}</span>}
          {Boolean(data) && (
            <span>
              Data shows the last {data?.config?.timeframeInDays} days and
              compares it to a {data?.config?.timeframeInDays} days period{' '}
              {data?.config?.comparisonTimeFrameInDays} days before.
            </span>
          )}
        </Space>
      }>
      <InfoCircleOutlined />
    </Tooltip>
  )
}

const StatGroupTypeTitle = ({ groupType }) => {
  const [title, color] = (() => {
    switch (groupType) {
      case ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE:
        return ['Simple', groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]]

      case ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC:
        return [
          'Organic',
          groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC],
        ]

      case ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT:
        return [
          'Statement',
          groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT],
        ]

      case ARTIFICIAL_TELL_GROUP_TYPES.OPEN:
        return ['Open', groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.OPEN]]

      case ARTIFICIAL_TELL_GROUP_TYPES.SHARE:
        return ['Share', groupTypeColorMap[ARTIFICIAL_TELL_GROUP_TYPES.SHARE]]

      case 'ALL':
        return ['All', groupTypeColorMap.ALL]

      default:
        return ['Unknown', 'grey']
    }
  })()

  return (
    <Space>
      <Badge color={color} />
      <span>{title}</span>
    </Space>
  )
}

const dummyData = {
  value: '-',
  change: { percentage: '-' },
}

const getShortnameValue = (shortnameData) => {
  if (!shortnameData) {
    return dummyData.value
  }

  switch (shortnameData.valueType) {
    case 'percentage':
      return `${(shortnameData.value.current * 100).toFixed(1)}%`
    case 'number':
      return Math.round(shortnameData.value.current)
    default:
      return shortnameData.value.current
  }
}

const getTrendProps = (shortnameData, style) => {
  // Return early if no data or no change
  if (!shortnameData?.change?.percentage) {
    return {}
  }

  const { isHigherBetter, change } = shortnameData
  const isPositiveChange = change.percentage > 0

  const positiveColor = 'rgb(82, 196, 26)'
  const negativeColor = 'rgb(245, 34, 45)'

  // Determine if trend should be shown as positive or negative
  const showAsPositive = isHigherBetter ? isPositiveChange : !isPositiveChange

  // Use up arrow for positive trend, down arrow for negative
  const Icon = showAsPositive ? CaretUpOutlined : CaretDownOutlined
  const color = showAsPositive ? positiveColor : negativeColor

  return {
    prefix: <Icon style={{ color, ...style }} />,
  }
}

const getChangePercentage = (shortnameData) => {
  if (!shortnameData?.change?.percentage) {
    return dummyData.change.percentage
  }

  return `${(shortnameData.change.percentage * 100).toFixed(1)}%`
}

const getLangFlag = (lang) => {
  switch (lang) {
    case 'ar':
      return CountryPhoneCodes.find((c) => c.code === 'SA') ?? {}

    case 'en':
      return CountryPhoneCodes.find((c) => c.code === 'US') ?? {}

    case 'hi':
      return CountryPhoneCodes.find((c) => c.code === 'IN') ?? {}

    default:
      return CountryPhoneCodes.find((c) => c.code === lang.toUpperCase()) ?? {}
  }
}

/**
 * Ant design pro components seem to have issues when using custom components with ProCard and StatisticCard.Group as children.
 * Rendering in a row does not work when the custom components are children, so we use this function to reuse prop generation instead.
 */
const getStatsCardProps = ({ config, title, data, statistic }) => {
  return {
    ...(typeof title === 'string'
      ? { title: <CopyableTitle title={title} id={data?.id} /> }
      : {}),
    statistic: {
      ...statistic,
      value: getShortnameValue(data),
      description: (
        <Statistic
          title={
            statistic?.description?.title === null
              ? undefined
              : statistic?.description?.title ??
                `${data?.aggregationType} last ${
                  config?.timeframeInDays ?? '-'
                }d`
          }
          value={getChangePercentage(data)}
          {...getTrendProps(data)}
        />
      ),
    },
  }
}

const ChangePercentageCell = ({ item, fontType = 'micro' }) => {
  const { prefix } = getTrendProps(item)

  return (
    <Text type={fontType}>
      {prefix}
      <Text type={fontType} style={{ marginLeft: 2 }}>
        {`${(item.change.percentage * 100).toFixed(1)}%`}
      </Text>
    </Text>
  )
}

const DataCell = ({ item }) => {
  const screens = Grid.useBreakpoint()

  return (
    <Box
      transparent
      flexDirection={screens.sm ? 'row' : 'column'}
      alignItems="center">
      <Text semibold type="small" style={{ marginRight: 4 }}>
        {formatNumber(item.value.current)}
      </Text>
      <ChangePercentageCell item={item} />
    </Box>
  )
}

const onHeaderCellCommon = () => {
  return {
    style: {
      backgroundColor: '#FFF',
      fontSize: 12,
    },
  }
}

// Removes bottom border of the last item
const onCellCreator = (rowsLength) => (record, rowIndex) => ({
  style: {
    ...(rowIndex === rowsLength - 1 ? { borderBottomWidth: 0 } : {}),
    paddingTop: 8,
    paddingBottom: 8,
  },
})

// key supports array of keys to access nested value
const defaultSorter = (key, priority) => ({
  multiple: priority,
  compare: (a, b) => {
    const aValue = R.path(Array.isArray(key) ? key : [key], a)
    const bValue = R.path(Array.isArray(key) ? key : [key], b)

    return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
  },
})

const availableContentGroupTypes = [
  ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
  ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
  ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
  ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
  ARTIFICIAL_TELL_GROUP_TYPES.SHARE,
]

const AllDataTable = () => {
  const availableLanguages = useAvailableLanguages()

  const { data } = useDashboardQuery()

  const dataset = React.useMemo(() => {
    if (!data) return []

    return availableLanguages.reduce((acc, lang) => {
      availableContentGroupTypes.forEach((groupType) => {
        const languageLong = DETECTABLE_LANGUAGE[langDetectObjectsByType1[lang]]
        const groupData =
          data?.data[
            `CH-ArTells-ActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[groupType]}`
          ]
        const sendPerGroupData =
          data?.data[
            `CH-ArTells-SentPerActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[groupType]}`
          ]

        if (!groupData || !sendPerGroupData) {
          return
        }

        acc.push({
          id: groupData.id,
          language: lang,
          flag: getLangFlag(lang).flag,
          activeGroups: groupData,
          sendPerGroups: sendPerGroupData,
          groupType,
        })
      })

      return acc
    }, [])
  }, [availableLanguages, data])

  const columns = [
    {
      dataIndex: 'language',
      title: 'Language',
      width: 140,
      align: 'center',
      filters: availableLanguages.map((lang) => ({
        text: lang.toUpperCase(),
        value: lang,
      })),
      filterMultiple: true,
      onFilter: (value, record) => value === record.language,
      render: (text, record) => (
        <span>{`${record.flag} ${text.toUpperCase()}`}</span>
      ),
      sorter: defaultSorter('language', 4),
    },
    {
      title: 'Group Type',
      dataIndex: 'groupType',
      width: 140,
      render: (text) => <StatGroupTypeTitle groupType={text} />,
      sorter: defaultSorter('groupType', 3),
    },
    {
      title: 'Amount Groups',
      dataIndex: 'activeGroups',
      width: 180,
      render: (item) => <span>{formatNumber(item.value.current)}</span>,
      sorter: defaultSorter(['activeGroups', 'value', 'current'], 1),
    },
    {
      title: 'Change groups',
      dataIndex: 'activeGroups',
      key: 'id',
      width: 180,
      render: (item) => <ChangePercentageCell item={item} fontType="small" />,
    },
    {
      title: 'Sent per group / day',
      dataIndex: 'sendPerGroups',
      width: 180,
      render: (item) => <span>{formatNumber(item.value.current)}</span>,
      sorter: defaultSorter(['sendPerGroups', 'value', 'current'], 2),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Change sent',
      dataIndex: 'sendPerGroups',
      key: 'id',
      width: 180,
      render: (item) => <ChangePercentageCell item={item} fontType="small" />,
    },
    // Spacer for larger screens to have data better grouped together
    { key: 'id' },
  ].map((column) => ({
    ...column,
    onHeaderCell: onHeaderCellCommon,
    onCell: onCellCreator(dataset.length),
  }))

  return (
    <Table
      dataSource={dataset}
      columns={columns}
      bordered={false}
      pagination={false}
      scroll={{ y: 300 }}
    />
  )
}

const GroupTypeByLangTable = () => {
  const dispatch = ReactRedux.useDispatch()
  const availableLanguages = useAvailableLanguages()

  const { data } = useDashboardQuery()

  const dataset = React.useMemo(() => {
    if (!data) return []

    return availableLanguages.reduce((acc, lang) => {
      const item = {
        id: lang,
        language: lang,
        flag: getLangFlag(lang).flag,
      }

      availableContentGroupTypes.forEach((groupType) => {
        const languageLong = DETECTABLE_LANGUAGE[langDetectObjectsByType1[lang]]
        const groupData =
          data?.data[
            `CH-ArTells-ActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[groupType]}`
          ]

        item[groupType] = groupData?.value?.current ?? '-'
      })

      acc.push(item)

      return acc
    }, [])
  }, [availableLanguages, data])

  const onRow = (record) => ({
    onClick: () => {
      dispatch(setLanguage(record.language))
    },
  })

  const onHeaderCell = () => {
    return R.mergeDeepRight(onHeaderCellCommon(), {
      style: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    })
  }

  const columns = [
    {
      dataIndex: 'language',
      align: 'center',
      title: <Box style={{ width: 55, height: 4 }} />,
      width: 80,
      render: (text, record) => (
        <span>{`${record.flag} ${text.toUpperCase()}`}</span>
      ),
    },
    ...availableContentGroupTypes.map((groupType) => ({
      dataIndex: groupType,
      align: 'right',
      title: <StatGroupTypeTitle groupType={groupType} />,
      width: 100,
      render: (text) => <span>{formatNumber(text)}</span>,
    })),
    // Spacer for larger screens to have data better grouped together
    { key: 'id', responsive: ['xxl'] },
  ].map((column) => ({
    ...column,
    onHeaderCell,
    onCell: onCellCreator(dataset.length),
  }))

  return (
    <Table
      dataSource={dataset}
      columns={columns}
      bordered={false}
      pagination={false}
      onRow={onRow}
      scroll={{ x: true }}
    />
  )
}

const GlobalSection = () => {
  const [tab, setTab] = React.useState(ARTIFICIAL_TELL_GROUP_TYPES.OPEN)

  const dispatch = ReactRedux.useDispatch()
  const availableLanguages = useAvailableLanguages()
  const { data, isLoading } = useDashboardQuery()

  const datasetByGroupType = React.useMemo(() => {
    if (!data) return []

    return availableLanguages.reduce((acc, lang) => {
      const languageLong = DETECTABLE_LANGUAGE[langDetectObjectsByType1[lang]]
      const groupData =
        data?.data[
          `CH-ArTells-ActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[tab]}`
        ]
      const sendPerGroupData =
        data?.data[
          `CH-ArTells-SentPerActiveGroups-${languageLong}-${ARTIFICIAL_TELL_GROUP_TYPES[tab]}`
        ]

      if (!groupData || !sendPerGroupData) {
        return acc
      }

      acc.push({
        id: groupData.id,
        language: lang,
        flag: getLangFlag(lang).flag,
        activeGroups: groupData,
        sendPerGroups: sendPerGroupData,
      })

      return acc
    }, [])
  }, [availableLanguages, data, tab])

  const maxAmountGroups = Math.max(
    ...datasetByGroupType.map((item) => item.activeGroups.value.current)
  )

  const onRow = (record) => ({
    onClick: () => {
      dispatch(setLanguage(record.language))
    },
  })

  const onHeaderCell = () => {
    return R.mergeDeepRight(onHeaderCellCommon(), {
      style: {
        paddingLeft: 8,
        paddingRight: 8,
      },
    })
  }

  const columns = [
    {
      dataIndex: 'language',
      key: 'id',
      width: 120,
      align: 'center',
      render: (text, record) => (
        <span>{`${record.flag} ${text.toUpperCase()}`}</span>
      ),
    },
    {
      dataIndex: 'activeGroups',
      key: 'id',
      width: 280,
      responsive: ['lg'],
      render: (item) => (
        <Progress
          type="line"
          status="active"
          showInfo={false}
          percent={(item.value.current * 100) / maxAmountGroups}
        />
      ),
    },
    {
      title: 'Amount Groups',
      key: 'id',
      dataIndex: 'activeGroups',
      width: 160,
      render: (item) => <DataCell item={item} />,
    },
    {
      title: 'Sent per group / day',
      key: 'id',
      dataIndex: 'sendPerGroups',
      width: 160,
      render: (item) => <DataCell item={item} />,
    },
  ]
    .filter(Boolean)
    .map((column) => ({
      ...column,
      onHeaderCell,
      onCell: onCellCreator(datasetByGroupType.length),
    }))

  return (
    <ProCard ghost gutter={[12, 12]} wrap style={{ marginBottom: 24 }}>
      <ProCard
        bodyStyle={!isLoading ? { padding: 0 } : {}}
        bordered
        colSpan={{ lg: 24, xl: 11, xxl: 8 }}
        title="Active groups"
        headerBordered
        loading={isLoading}>
        <GroupTypeByLangTable />
      </ProCard>

      <ProCard
        title="Active groups: By group type"
        headerBordered
        bordered
        colSpan={{ lg: 24, xl: 13, xxl: 14 }}
        tabs={{
          tabPosition: 'left',
          activeKey: tab,
          cardProps: {
            loading: isLoading,
            ghost: !isLoading, // To maintain padding for the loading state of the card
          },
          items: availableContentGroupTypes.map((groupType) => ({
            label: <StatGroupTypeTitle groupType={groupType} />,
            key: groupType,
            children: (
              <ProCard ghost>
                <Table
                  dataSource={datasetByGroupType}
                  columns={columns}
                  bordered={false}
                  pagination={false}
                  onRow={onRow}
                />
              </ProCard>
            ),
          })),
          onChange: (key) => {
            setTab(key)
          },
        }}
      />

      <ProCard
        bodyStyle={!isLoading ? { padding: 0 } : {}}
        bordered
        collapsible
        colSpan={24}
        defaultCollapsed
        headerBordered
        loading={isLoading}
        size="small"
        title="All countries group type data"
        extra={
          <InfoTooltip
            data={data}
            description="Shows the raw data of amount groups and sent per group per day for each language and each type. Use this for more fine grained investigations. For an easier overview see the cards below. All use the same underlaying data."
          />
        }>
        <AllDataTable />
      </ProCard>
    </ProCard>
  )
}

const dataFilters = ['Top', 'P80', 'Median', 'Worst']

const TooltipOtherChangeRates = ({ text, data, shBase, shTypes }) => {
  return (
    <Box transparent flexDirection="row" justifyContent="space-between">
      <span>{text}</span>
      <Tooltip
        placement="leftBottom"
        title={
          <Space direction="vertical">
            <span>All change rates</span>
            {dataFilters.map((filter) => (
              <Box
                key={`${shBase}-${filter}-${shTypes}`}
                transparent
                flexDirection="row"
                justifyContent="space-between"
                width={180}>
                <span>{`${filter} AR`}</span>
                <span>{`${getChangePercentage(
                  data?.data[`${shBase}-${filter}-${shTypes}`]
                )}`}</span>
              </Box>
            ))}
          </Space>
        }>
        <InfoCircleOutlined style={{ color: colors.grey[5] }} />
      </Tooltip>
    </Box>
  )
}

const CardAvailableContent = ({ userGroupType }) => {
  const { data } = useDashboardQuery()

  const userGroupTypeString = userGroupTypeShortnameMap[userGroupType]

  return (
    <ProCard ghost wrap>
      {availableContentGroupTypes.map((groupType, index) => {
        const shBase = 'CH-ArTells-Sent-GroupRate'
        const groupTypeString = ARTIFICIAL_TELL_GROUP_TYPES[groupType]
        const shTypes = `${userGroupTypeString}-${groupTypeString}`

        const shortnameData = data?.data[`${shBase}-Median-${shTypes}`]

        return (
          <StatisticCard
            key={`${shortnameData?.id ?? index}-${groupType}`}
            colSpan={{ md: 24, lg: 8, xl: '20%' }}
            title={<StatGroupTypeTitle groupType={groupType} />}
            statistic={{
              description: (
                <TooltipOtherChangeRates
                  text="Median change"
                  data={data}
                  shBase={shBase}
                  shTypes={shTypes}
                />
              ),
              value: getChangePercentage(shortnameData),
              ...getTrendProps(shortnameData, { fontSize: 16 }),
            }}
            footer={dataFilters.map((filter) => (
              <Statistic
                key={`${shBase}-${filter}-${shTypes}`}
                value={getShortnameValue(
                  data?.data[`${shBase}-${filter}-${shTypes}`]
                )}
                title={
                  <CopyableTitle
                    title={`${filter} AR`}
                    id={data?.data[`${shBase}-${filter}-${shTypes}`]?.id}
                  />
                }
                layout="horizontal"
              />
            ))}
          />
        )
      })}
    </ProCard>
  )
}

const twoStatsCardColSpan = { xl: 12, xxl: 8 }
const twoStatsColSpan = { xs: 24, sm: 12 }

const userGroupTypes = [
  ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.MEDIUM,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL,
]

const noContentGroupTypes = [
  'ALL',
  ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
  ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
  ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
  ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
]

const SectionAvailableContent = () => {
  const [tab, setTab] = React.useState(ARTIFICIAL_TELL_USER_GROUP_TYPE.REAL)

  const { data, isLoading } = useDashboardQuery()

  return (
    <ProCard ghost gutter={[12, 12]} wrap>
      <ProCard
        bordered
        colSpan={{ xl: 24, xxl: 24 }}
        extra={
          <InfoTooltip
            data={data}
            description="Insights into the quality of our content for this market. Breaks down by user group type (e.g. Low) and group type (e.g. Simple) to clearly identify where we might need new content or have underperforming groups."
          />
        }
        headerBordered
        title="Available Content: Answer rate"
        tabs={{
          tabPosition: 'left',
          activeKey: tab,
          cardProps: {
            loading: isLoading,
          },
          items: userGroupTypes.map((userGroupType) => ({
            label: capitalize(userGroupTypeLabelMap[userGroupType]),
            key: userGroupType,
            children: <CardAvailableContent userGroupType={userGroupType} />,
          })),
          onChange: (key) => {
            setTab(key)
          },
        }}
      />
      <ProCard
        bordered
        colSpan={{ xl: 12, xxl: 16 }}
        extra={
          <InfoTooltip
            data={data}
            description="Indicates that there is too few content in this group type (e.g. Simple) so a fallback needs to be used."
          />
        }
        headerBordered
        loading={isLoading}
        title="No content events">
        <ProCard.Group wrap>
          {noContentGroupTypes.map((groupType, index) => {
            const groupTypeString =
              groupType === 'ALL'
                ? 'ALL'
                : ARTIFICIAL_TELL_GROUP_TYPES[groupType]
            const shNoContent = `CH-ArTells-Sent-NoContent-ALL-${groupTypeString}`

            const shortnameData = data?.data[shNoContent]

            const onPressOpenChart = () => {
              const params = {
                timeInterval: 'day',
                entries: [
                  ['event_time', '>', dayjs().subtract(14, 'day').unix()],
                  ['event_time', '<', dayjs().unix()],
                  groupType !== 'ALL' && ['group_type', '==', groupTypeString],
                ].filter(Boolean),
                selectedTable: 'backend_tell_artificial_no_content',
              }

              history.push({
                pathname: '/stats/chevents',
                search: `?${qs.stringify(params)}`,
              })
            }

            return (
              <StatisticCard
                colSpan={{ xs: 12, sm: 8, md: '20%', xl: 8, xxl: '20%' }}
                key={shortnameData?.id ?? index}
                title={<StatGroupTypeTitle groupType={groupType} />}
                statistic={{ value: getShortnameValue(shortnameData) }}
                extra={<LineChartOutlined onClick={onPressOpenChart} />}
              />
            )
          })}
        </ProCard.Group>
      </ProCard>

      <ProCard
        bordered
        colSpan={twoStatsCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            description="Indicates that there are too few groups with high answer rate in this language so low performing groups are used. Low AR is bottom 20% and very low AR is bottom 10%."
          />
        }
        headerBordered
        loading={isLoading}
        title="Low content events">
        <ProCard.Group wrap>
          <StatisticCard
            colSpan={twoStatsColSpan}
            key={data?.data['CH-ArTells-Sent-HIGH_AR-Low-AR'].id}
            title={
              <CopyableTitle
                title="Low AR"
                id={data?.data['CH-ArTells-Sent-HIGH_AR-Low-AR'].id}
              />
            }
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ArTells-Sent-HIGH_AR-Low-AR'],
            })}
          />
          <StatisticCard
            colSpan={twoStatsColSpan}
            key={data?.data['CH-ArTells-Sent-HIGH_AR-Very-Low-AR'].id}
            title={
              <CopyableTitle
                title="Very low AR"
                id={data?.data['CH-ArTells-Sent-HIGH_AR-Very-Low-AR'].id}
              />
            }
            {...getStatsCardProps({
              config: data?.config,
              data: data?.data['CH-ArTells-Sent-HIGH_AR-Very-Low-AR'],
            })}
          />
        </ProCard.Group>
      </ProCard>
    </ProCard>
  )
}

const SectionUserData = () => {
  const { data, isLoading } = useDashboardQuery()

  return (
    <ProCard ghost gutter={[12, 12]} wrap>
      <ProCard
        bordered
        wrap
        colSpan={twoStatsCardColSpan}
        extra={<InfoTooltip data={data} />}
        headerBordered
        loading={isLoading}
        title="Active Users">
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Active Answerer / week',
            config: data?.config,
            data: data?.data['CH-Answer-Create-Weekly'],
          })}
        />
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Total WAUs',
            config: data?.config,
            data: data?.data['CH-Active-WAUs'],
          })}
        />
      </ProCard>

      <ProCard
        bordered
        wrap
        colSpan={twoStatsCardColSpan}
        extra={<InfoTooltip data={data} />}
        headerBordered
        loading={isLoading}
        title="Active Users Answering">
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: '% DAUs',
            config: data?.config,
            data: data?.data['CH-Tell-Answer-DAUs'],
          })}
        />
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: '% WAUs',
            config: data?.config,
            data: data?.data['CH-Tell-Answer-WAUs'],
          })}
        />
      </ProCard>

      <ProCard
        bordered
        wrap
        colSpan={twoStatsCardColSpan}
        extra={<InfoTooltip data={data} />}
        headerBordered
        loading={isLoading}
        title="Active Users Sharing">
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Profile',
            config: data?.config,
            data: data?.data['CH-ShareCards-Share-Open'],
          })}
        />
        <StatisticCard
          colSpan={twoStatsColSpan}
          {...getStatsCardProps({
            title: 'Answers',
            config: data?.config,
            data: data?.data['CH-Share-Answer-Open'],
          })}
        />
      </ProCard>
    </ProCard>
  )
}

const userGroupStatsColSpan = { xs: 24, sm: 12, lg: 8, xl: 6, xxl: 8 }

const UserGroupStats = ({ userGroupType }) => {
  const { data } = useDashboardQuery()

  const userGroupTypeString = userGroupTypeShortnameMap[userGroupType]

  return (
    <StatisticCard.Group wrap>
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'Amount sent',
          config: data?.config,
          data: data?.data[`CH-ArTells-Sent-${userGroupTypeString}`],
        })}
      />
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'Median Answer rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Median-${userGroupTypeString}`
          ],
        })}
      />
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'p80 Answer rate',
          config: data?.config,
          data: data?.data[`CH-ArTells-AnswerRate-P80-${userGroupTypeString}`],
        })}
      />
      <StatisticCard
        colSpan={userGroupStatsColSpan}
        {...getStatsCardProps({
          title: 'Total answer rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Total-${userGroupTypeString}`
          ],
        })}
      />
    </StatisticCard.Group>
  )
}

const scheduleStatsColSpan = { xs: 24, md: 24, lg: 8, xxl: 12 }

const ScheduleStats = ({ schedule }) => {
  const { data } = useDashboardQuery()

  const parsedSchedule = schedule.replace(' ', '-')

  return (
    <ProCard ghost wrap>
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'Amount sent',
          config: data?.config,
          data: data?.data[`CH-ArTells-Sent-Schedule-${parsedSchedule}`],
        })}
      />
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'Median Answer rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Median-Schedule-${parsedSchedule}`
          ],
        })}
      />
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'p80 Answer rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-P80-Schedule-${parsedSchedule}`
          ],
        })}
      />
      <StatisticCard
        colSpan={scheduleStatsColSpan}
        {...getStatsCardProps({
          title: 'Total answer rate',
          config: data?.config,
          data: data?.data[
            `CH-ArTells-AnswerRate-Total-Schedule-${parsedSchedule}`
          ],
        })}
      />
    </ProCard>
  )
}

const userGroups = [
  ARTIFICIAL_TELL_USER_GROUP_TYPE.LOW,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.MEDIUM,
  ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  'ALL',
]

const userGroupAndScheduleCardColSpan = { xl: 24, xxl: 12 }

const SectionUserGroupAndScheduleData = () => {
  const screens = Grid.useBreakpoint()
  const [tabUserGroups, setTabUserGroups] = React.useState('ALL')
  const [tabSchedules, setTabSchedules] = React.useState()

  const { data, isLoading } = useDashboardQuery()

  React.useEffect(() => {
    setTabSchedules(data?.config?.schedules?.[0])
  }, [data?.config?.schedules])

  return (
    <ProCard ghost gutter={[12, 12]} wrap>
      <ProCard
        bordered
        colSpan={userGroupAndScheduleCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            description="Shows the distribution of the user base between the user group types (e.g. Low). This indicates how much content is needed in a given market. A market with a lot of users in high requires a lot of content."
          />
        }
        headerBordered
        title="User Group Data"
        tabs={{
          tabPosition: 'left',
          activeKey: tabUserGroups,
          cardProps: {
            loading: isLoading,
          },
          items: userGroups.map((userGroupType) => ({
            label: capitalize(userGroupTypeLabelMap[userGroupType]),
            key: userGroupType,
            children: <UserGroupStats userGroupType={userGroupType} />,
          })),
          onChange: (key) => {
            setTabUserGroups(key)
          },
        }}
      />
      <ProCard
        bordered
        colSpan={userGroupAndScheduleCardColSpan}
        extra={
          <InfoTooltip
            data={data}
            description="Tabs lets you select between the different sending schedules that we use to send out tells."
          />
        }
        headerBordered
        title="Schedule Data"
        tabs={{
          style: { height: screens.lg ? 360 : 563 }, // 563 is manually measured with page inspector, size of all ScheduleStats cards being rendered vertically
          tabPosition: 'left',
          activeKey: tabSchedules,
          cardProps: {
            loading: isLoading,
          },
          items: (data?.config?.schedules ?? []).map((schedule) => ({
            label: capitalize(schedule.toLowerCase()),
            key: schedule,
            children: <ScheduleStats schedule={schedule} />,
          })),
          onChange: (key) => {
            setTabSchedules(key)
          },
        }}
      />
    </ProCard>
  )
}

const StatsTab = () => {
  return (
    <ProCard ghost>
      <SectionAvailableContent />
      <SectionUserData />
      <SectionUserGroupAndScheduleData />
    </ProCard>
  )
}

export const PageArtificialTellsDashboard = () => {
  const dispatch = ReactRedux.useDispatch()
  const screens = Grid.useBreakpoint()
  const containerStyle = hooks.usePageContainerStyle()
  const activeLanguage = ReactRedux.useSelector(getLanguage)
  const availableLanguages = useAvailableLanguages()

  const getThemedColor = hooks.useThemedColor()

  const changeActiveLanguage = (lang) => {
    dispatch(setLanguage(lang))
  }

  return (
    <ScrollView
      style={containerStyle}
      contentContainerStyle={{
        flexGrow: 1,
        backgroundColor: getThemedColor(colors.grey[3], colors.black[5]),
        paddingBottom: 240,
      }}>
      <Box
        paddingTop={32}
        paddingHorizontal={32}
        paddingBottom={16}
        flexDirection={screens.xxl ? 'row' : 'column'}
        borderBottomWidth={1}
        borderBottomStyle="solid"
        borderColor={getThemedColor(colors.grey[4], colors.black[5])}
        marginBottom={24}>
        <Box flex={1} paddingRight={32}>
          <Typography.Title>Dashboard</Typography.Title>
          <Box flexDirection="row">
            <Typography.Link
              href="https://miro.com/app/board/uXjVL5SMElk=/"
              target="_blank">
              <ExportOutlined /> Control Center
            </Typography.Link>
            <Typography.Link
              href="https://miro.com/app/board/uXjVNVgHtuA=/?moveToWidget=3458764568727385660&cot=14"
              target="_blank"
              style={styleSheets.margin.left[24]}>
              <ExportOutlined /> ModCP system docs
            </Typography.Link>
            <Typography.Link
              href="https://miro.com/app/board/uXjVMx5a11s=/"
              target="_blank"
              style={styleSheets.margin.left[24]}>
              <ExportOutlined /> Schedule docs
            </Typography.Link>
          </Box>
        </Box>
      </Box>

      <Box transparent paddingHorizontal={screens.md ? 32 : 4} maxWidth={2150}>
        <GlobalSection />

        <Box
          backgroundColor={getThemedColor(colors.grey[3], colors.black[5])}
          position="sticky"
          top={0}
          zIndex={10}>
          <Tabs
            activeKey={activeLanguage}
            onChange={changeActiveLanguage}
            size="large"
            items={availableLanguages.map((lang) => {
              const { flag } = getLangFlag(lang)

              return {
                label: `${flag} ${lang.toUpperCase()}`,
                key: lang,
              }
            })}
            style={{ position: 'sticky', top: 0, zIndex: 10 }}
          />
        </Box>

        <StatsTab />
      </Box>
    </ScrollView>
  )
}
