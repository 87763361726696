import { useDebounceCallback } from '@react-hook/debounce'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_SENDER_HINT_TYPE,
} from '@tellonym/enums/lib/Tell'
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, ScrollView } from '../../common'
import { updateLocationSearch } from '../../common/helpers'
import {
  createGroups,
  generateSpeedContent,
  generateSpeedContentParams,
  refreshTopic,
} from '../actionsV2'
import {
  getHeaderMode,
  getIsRefreshingSpeedContent,
  getIsRefreshingSpeedContentParams,
  getIsSavingGroups,
  getLanguage,
  getSpeedContentByTopicId,
  getSpeedContentParamsByTopicId,
} from '../selectorsV2'
import { Breadcrumbs } from './BreadcrumbHeader'
import { SelectorsLanguage } from './SelectorsLanguage'

const PromptForm = ({ formState, handleFormStateChange }) => {
  return (
    <Form labelCol={{ span: 4 }} layout="horizontal">
      <Form.Item label="Input">
        <Input
          value={formState.promptInput}
          onChange={(e) => {
            handleFormStateChange({ promptInput: e.target.value })
          }}
        />
      </Form.Item>
      <Form.Item label="Model">
        <Select
          value={formState.model}
          onChange={(model) => {
            handleFormStateChange({ model })
          }}>
          <Select.Option value="chatgpt">ChatGPT</Select.Option>
          <Select.Option value="claude">Claude</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Prompt">
        <Select
          value={formState.promptStarter}
          onChange={(promptStarter) => {
            handleFormStateChange({ promptStarter })
          }}>
          <Select.Option value={0}>Sentence-Starters 1</Select.Option>
          <Select.Option value={1}>Sentence-Starters 2</Select.Option>
          <Select.Option value={2}>Sentence-Starters 3</Select.Option>
          <Select.Option value={3}>Sentence-Starters 4</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Amount">
        <InputNumber
          min={1}
          max={50}
          value={formState.amount}
          onChange={(amount) => {
            handleFormStateChange({ amount })
          }}
        />
      </Form.Item>
      <Form.Item>
        <TextArea
          value={formState.promptContext}
          onChange={(e) => {
            handleFormStateChange({ promptContext: e.target.value })
          }}
          rows={4}
          style={{ maxWidth: '100%', flex: 1, height: 200 }}
          placeholder="Dear ChatGPT, please do XY..."
        />
      </Form.Item>
    </Form>
  )
}

const GeneratedContent = ({ name, index, setGroup }) => {
  const [inputValue, setInputValue] = React.useState(name)
  const [isAccepted, setIsAccepted] = React.useState(false)

  const onChange = (e) => {
    setInputValue(e.target.value)
  }

  const onToggleAccept = (e) => {
    if (e.target.checked) {
      setIsAccepted(true)
      setGroup(index, inputValue)
    } else {
      setIsAccepted(false)
      setGroup(index, undefined)
    }
  }

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      gap={8}
      paddingRight={8}
      paddingBottom={2}>
      <Input.TextArea
        autoSize
        disabled={isAccepted}
        bordered
        showCount={false}
        onChange={onChange}
        value={inputValue}
      />
      <Checkbox
        name="isAccepted"
        checked={isAccepted}
        onChange={onToggleAccept}
      />
    </Box>
  )
}

export const PageArtificialTellsTopicContentSpeedGeneration = ({ match }) => {
  const dispatch = ReactRedux.useDispatch()

  const topicId = parseInt(match.params.topicId, 10)

  const headerMode = ReactRedux.useSelector(getHeaderMode)
  const language = ReactRedux.useSelector(getLanguage)

  const allData = ReactRedux.useSelector(
    (state) => state.artificialTellsV2.topicDetails[topicId]
  )

  const speedContentByTopicId = ReactRedux.useSelector(getSpeedContentByTopicId)
  const speedContentParamsByTopicId = ReactRedux.useSelector(
    getSpeedContentParamsByTopicId
  )

  const speedContent = speedContentByTopicId[allData?.topic?.id] ?? []
  const speedContentParams =
    speedContentParamsByTopicId[allData?.topic?.id] ?? {}

  const isRefreshingSpeedContent = ReactRedux.useSelector(
    getIsRefreshingSpeedContent
  )

  const isRefreshingSpeedContentParams = ReactRedux.useSelector(
    getIsRefreshingSpeedContentParams
  )

  const isSavingGroups = ReactRedux.useSelector(getIsSavingGroups)

  const [groups, setGroups] = React.useState([])

  const setGroup = (index, text) => {
    setGroups((groups) => {
      const newGroups = [...groups]
      newGroups[index] = text
      return newGroups
    })
  }

  const [groupState, setGroupState] = React.useState({
    depth: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
    type: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
    senderHintType: ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC,
  })

  const handleGroupStateChange = (payload) => {
    setGroupState((groupState) => ({ ...groupState, ...payload }))
  }

  const [formState, setFormState] = React.useState({
    promptInput: '',
    model: 'chatgpt',
    promptStarter: 0,
    amount: 7,
    promptContext: '',
  })

  const handleFormStateChange = (payload) => {
    setFormState((formState) => ({ ...formState, ...payload }))
  }

  const handleGeneratePress = () => {
    dispatch(
      generateSpeedContent({
        topicId,
        prompt: formState.promptContext,
      })
    )
  }

  const onPressSave = () => {
    dispatch(
      createGroups({ ...groupState, topicId, content: groups.filter(Boolean) })
    )
  }

  const existingGroups = React.useMemo(
    () => allData?.groups.slice(0).reverse() ?? [],
    [allData?.groups]
  )

  const updateParams = useDebounceCallback(
    React.useCallback(
      (formState) => {
        const payload = {
          topicId,
        }

        for (const [key, value] of Object.entries(formState)) {
          if (['promptContext'].includes(key)) {
            continue
          }

          if (typeof value !== 'undefined' && value !== '') {
            payload[key] = value
          }
        }
        dispatch(generateSpeedContentParams(payload))
      },
      [dispatch, topicId]
    ),
    500,
    false
  )

  React.useEffect(() => {
    updateLocationSearch({ headerMode })
  }, [headerMode])

  React.useEffect(() => {
    dispatch(refreshTopic({ topicId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, topicId])

  React.useEffect(() => {
    if (!topicId) {
      return
    }

    updateParams(formState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formState.amount,
    formState.model,
    formState.promptStarter,
    formState.promptInput,
    topicId,
  ])

  React.useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      promptInput: speedContentParams.possibleParams?.promptInput ?? '',
    }))
  }, [speedContentParams.possibleParams?.promptInput])

  React.useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      promptContext: speedContentParams.generatedPrompt ?? '',
    }))
  }, [speedContentParams.generatedPrompt])

  if (!allData?.topic) {
    return null
  }

  return (
    <Box flex={1}>
      <Box
        transparent
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingVertical={12}
        paddingHorizontal={24}>
        <Breadcrumbs language={language} topic={allData?.topic} />
        <SelectorsLanguage languageStatusList={allData?.topic?.languages} />
      </Box>

      <Box flexDirection="row" height={642}>
        <Box transparent flex={1}>
          <Card
            size="small"
            title={
              <Typography.Text strong style={{ fontSize: 16 }}>
                Generator
              </Typography.Text>
            }>
            <Box flex={1} transparent height={600}>
              <Box paddingBottom={12}>
                <Typography.Text type="secondary">Topic Name</Typography.Text>
                <Typography.Text strong style={{ fontSize: 16 }}>
                  {allData?.topic.name}
                </Typography.Text>
                <Typography.Text type="secondary">Description</Typography.Text>
                <Typography.Text>
                  {allData.topic.description || '-'}
                </Typography.Text>
              </Box>
              <PromptForm
                formState={formState}
                handleFormStateChange={handleFormStateChange}
              />
            </Box>
          </Card>
        </Box>

        <Box transparent flex={1}>
          <Card
            size="small"
            title={
              <Typography.Text strong style={{ fontSize: 16 }}>
                Generated Content
              </Typography.Text>
            }
            bodyStyle={{ padding: 0, paddingTop: 1, paddingLeft: 0 }}>
            <ScrollView style={{ height: 600 }}>
              {speedContent.map((name, index) => (
                <GeneratedContent
                  key={name}
                  name={name}
                  index={index}
                  setGroup={setGroup}
                />
              ))}
            </ScrollView>
          </Card>
        </Box>

        <Box transparent flex={1}>
          <Card
            size="small"
            title={
              <Typography.Text strong style={{ fontSize: 16 }}>
                Groups in Topic
              </Typography.Text>
            }
            bodyStyle={{ padding: 0, paddingTop: 1, paddingLeft: 0 }}>
            <ScrollView style={{ height: 600 }}>
              <ul style={{ paddingRight: 4 }}>
                {existingGroups.map((group) => (
                  <li>
                    <Typography.Text style={{ fontSize: 12 }}>
                      {group.name}
                    </Typography.Text>
                  </li>
                ))}
              </ul>
            </ScrollView>
          </Card>
        </Box>
      </Box>

      <Box flexDirection="row">
        <Box flex={1}>
          <Button
            loading={isRefreshingSpeedContent}
            onClick={handleGeneratePress}>
            Generate
          </Button>
        </Box>

        <Box flex={1} flexDirection="row" justifyContent="space-between">
          <Box flexDirection="row" alignItems="center">
            <Select
              value={groupState.senderHintType}
              onChange={(senderHintType) => {
                handleGroupStateChange({ senderHintType })
              }}
              style={{ width: 150 }}>
              <Select.Option
                value={ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC}>
                Pretend Organic
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL}>
                Reveal
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE}>
                None
              </Select.Option>
              <Select.Option
                value={ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT}>
                None Default
              </Select.Option>
            </Select>

            <Select
              value={groupState.type}
              onChange={(type) => {
                handleGroupStateChange({ type })
              }}
              style={{ width: 100 }}>
              <Select.Option value={ARTIFICIAL_TELL_GROUP_TYPES.OPEN}>
                Open
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC}>
                Organic
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_GROUP_TYPES.AI}>
                AI
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_GROUP_TYPES.SHARE}>
                Share
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE}>
                Simple
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT}>
                Statement
              </Select.Option>
            </Select>

            <Select
              value={groupState.depth}
              onChange={(depth) => {
                handleGroupStateChange({ depth })
              }}
              style={{ width: 100 }}>
              <Select.Option value={ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO}>
                Intro
              </Select.Option>
              <Select.Option value={ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP}>
                Deep
              </Select.Option>
            </Select>
          </Box>

          <Button
            disabled={
              !groups.filter(Boolean).length || isRefreshingSpeedContentParams
            }
            loading={isSavingGroups}
            type="primary"
            onClick={onPressSave}>
            Save
          </Button>
        </Box>
        <Box flex={1} />
      </Box>
    </Box>
  )
}
