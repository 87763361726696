import { colors } from '@tellonym/core/common/colorSystem'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import { Breadcrumb, Button, Skeleton, Typography } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  BoxSecondary,
  ScrollView,
  history,
  hooks,
  styleSheets,
  theme,
} from '../../common'
import { updateLocationSearch } from '../../common/helpers'
import { createTopic, refreshTopics } from '../actionsV2'
import { getIsRefreshingTopics, getLanguage } from '../selectorsV2'
import { ArtificialTellTopicsTable } from './ArtificialTellTopicsTable'
import { ButtonBack } from './ButtonBack'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { SelectorsLanguage } from './SelectorsLanguage'

const HeaderSkeleton = () => (
  <BoxSecondary paddingBottom={16} paddingHorizontal={24} paddingTop={24}>
    <Box alignItems="center" transparent flexDirection="row" paddingBottom={24}>
      <Box
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        transparent>
        <ButtonBack
          onPress={() => {
            history.goBack()
          }}
          style={{ marginRight: 12, width: 30, height: 30 }}
        />

        <Breadcrumb>
          <Breadcrumb.Item key="Topics">Topics</Breadcrumb.Item>
        </Breadcrumb>
      </Box>
    </Box>

    <Box flexDirection="row" justifyContent="space-between" transparent>
      <Skeleton
        active
        title
        paragraph={{ rows: 3 }}
        size="small"
        style={{ width: 115, marginRight: 48 }}
      />
      <Skeleton
        active
        title
        paragraph={{ rows: 5 }}
        size="small"
        style={{ width: 275 }}
      />
      <Skeleton.Button active size="large" style={{ width: 300 }} />
    </Box>

    <Skeleton.Button active style={{ width: 100, marginTop: 24 }} />
  </BoxSecondary>
)

const Header = ({ data }) => {
  const [isCreatingTopic, setIsCreatingTopic] = React.useState(false)

  const getThemedColor = hooks.useThemedColor()
  const dispatch = ReactRedux.useDispatch()
  const language = ReactRedux.useSelector(getLanguage)
  const isRefreshing = ReactRedux.useSelector(getIsRefreshingTopics)

  const onPressNewTopic = React.useCallback(() => {
    const name = `name ${Math.round(Math.random() * 1000000)}`

    dispatch(createTopic({ name }))
    setIsCreatingTopic(true)
  }, [])

  const onChangeDataMode = () => {
    dispatch(refreshTopics())
  }

  if (typeof data.topics === 'undefined') {
    return <HeaderSkeleton />
  }

  return (
    <BoxSecondary paddingBottom={16} paddingHorizontal={24} paddingTop={24}>
      <Box
        alignItems="center"
        transparent
        flexDirection="row"
        paddingBottom={24}>
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          transparent>
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Topics">Topics</Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>

      <Box flexDirection="row" justifyContent="space-between" transparent>
        <Box marginRight={48} justifyContent="space-between" transparent>
          <Box flexShrink={0} transparent flexDirection="row">
            <Typography.Title level={5}>
              {DETECTABLE_LANGUAGE[
                langDetectObjectsByType1[language]
              ].toUpperCase()}
            </Typography.Title>
            <Typography.Text
              style={
                styleSheets.margin.left[12]
              }>{`${data.activeCounts.topics} Topics`}</Typography.Text>
            <Typography.Text
              style={
                styleSheets.margin.left[12]
              }>{`${data.activeCounts.groups} Groups`}</Typography.Text>
          </Box>
        </Box>

        <MedianDataForDataMode
          hasTitle={false}
          isLoading={isRefreshing}
          onChange={onChangeDataMode}
          flexShrink={0}
          backgroundColor={getThemedColor(
            theme.colors.antdBackgroundElevated,
            colors.black[5]
          )}
        />

        <SelectorsLanguage />
      </Box>
      <Box flexDirection="row" justifyContent="space-between" transparent>
        <Button
          size="middle"
          onClick={onPressNewTopic}
          loading={isCreatingTopic}>
          Create Topic
        </Button>
      </Box>
    </BoxSecondary>
  )
}

export const PageArtificialTellsTopics = () => {
  const dispatch = ReactRedux.useDispatch()

  const table = hooks.useTableState()

  const language = ReactRedux.useSelector(getLanguage)

  const artificialTellsV2 = ReactRedux.useSelector(
    (state) => state.artificialTellsV2
  )

  const allData = artificialTellsV2.topics

  React.useEffect(() => {
    updateLocationSearch(table)
  }, [])

  React.useEffect(() => {
    dispatch(refreshTopics())
  }, [language])

  return (
    <Box>
      <Header language={language} data={allData} />
      <ScrollView>
        <ArtificialTellTopicsTable
          {...table}
          useFixedHeader
          dataSource={allData?.topics}
          language={language}
          locale={{
            emptyText: allData?.topics ? null : <Skeleton active={true} />,
          }}
          interestsList={allData?.allInterestsAvailablePerLang ?? []}
        />
      </ScrollView>
    </Box>
  )
}
