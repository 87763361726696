import { getThemedColor } from '@tellonym/core/common/colorSystem'
import React from 'react'
import { useTheme } from './useTheme'

export const useThemedColor = () => {
  const theme = useTheme()

  const memoizedGetThemedColor = React.useMemo(
    () => getThemedColor(theme),
    [theme]
  )

  return memoizedGetThemedColor
}
