import { colors } from '@tellonym/core/common/colorSystem'
import { Table } from 'antd'
import { mergeDeepRight } from 'ramda'
import React, { useEffect, useMemo, useState } from 'react'
import { useVT } from 'virtualizedtableforantd4'
import { queryElementHeight } from '../helpers'
import * as hooks from '../hooks'

const enhanceColumns = ({
  columns,
  filters,
  getThemedColor,
  hasSortersMultipleProp,
  hasZebraRows = false,
  hasSmallRows = false,
  sorterState,
  nestingMultiplier = 1,
}) =>
  columns.map((column, index) => {
    const newColumn = { ...column }

    if (typeof column.children !== 'undefined') {
      newColumn.children = enhanceColumns({
        columns: column.children,
        filters,
        getThemedColor,
        hasSmallRows,
        hasSortersMultipleProp,
        hasZebraRows,
        sorterState,
        nestingMultiplier: nestingMultiplier * 10,
      })
    } else {
      newColumn.filteredValue =
        filters?.[column.key]?.map?.(String) ??
        column.defaultFilteredValue?.map?.(String) ??
        null

      /**
       * sorterState can either be an object or an array containing these objects. The latter is the case
       * when the table has multiple sorters enabled (sorter.multiple).
       */
      const sorterColumnState = Array.isArray(sorterState)
        ? sorterState?.find((sorter) => sorter.columnKey === column.key)
        : sorterState?.columnKey === column.key
        ? sorterState
        : undefined

      if (column.sortOrder !== sorterColumnState?.order) {
        newColumn.sortOrder = sorterColumnState?.order ?? null
      }

      /**
       * Either set the multiple prop if it does not exists or change its number.
       * To maintain hierarchy we give the first column the highest number (column length) and decrease from there on.
       * If a column has sorter.multiple set, we add it to the total number of columns so it's priority is given.
       */
      if (hasSortersMultipleProp) {
        if (typeof column.sorter === 'function') {
          newColumn.sorter = {
            multiple: columns.length - index / nestingMultiplier,
            compare: column.sorter,
          }
        } else if (column.sorter?.multiple) {
          newColumn.sorter.multiple = column.sorter.multiple + columns.length
        }
      }

      const onCellHandlers = []

      if (hasZebraRows) {
        onCellHandlers.push((_, index) => {
          if (index % 2) {
            return {
              style: {
                backgroundColor: getThemedColor(
                  colors.grey[3],
                  colors.black[5]
                ),
              },
            }
          }

          return undefined
        })
      }

      if (hasSmallRows) {
        onCellHandlers.push(() => {
          return { style: { paddingTop: 4, paddingBottom: 4 } }
        })
      }

      if (onCellHandlers.length !== 0) {
        // Call all handlers and merge the results
        newColumn.onCell = (...props) => {
          // Call props.onCell last to let it possibly override the other handlers
          return [...onCellHandlers, props.onCell].reduce(
            (mergedProps, handler) => {
              if (typeof handler === 'function') {
                const result = handler?.(...props) ?? {}

                return mergeDeepRight(mergedProps, result)
              }

              return mergedProps
            },
            {}
          )
        }
      }
    }

    return newColumn
  })

/**
 * @param {number} offset set if this Table shouldn't fill the full height
 * @param {Function} onEndReached can be used to fetch additional data
 */
export const TableEndlessScroll = ({
  columns,
  dataSource = [],
  debug = false,
  filters,
  hasZebraRows = false,
  hasSmallRows = false,
  isEndless = true,
  offset = 0,
  onChange,
  onEndReached,
  pagination,
  scroll,
  sorter: sorterState,
  ...props
}) => {
  const getThemedColor = hooks.useThemedColor()
  const [scrollConfig, setScrollConfig] = useState({
    y: 0,
    x: false,
    ...scroll,
  })

  const headerHeight = queryElementHeight('.ant-table-header')
  const paginationHeight = isEndless ? 0 : 64 // .ant-table-pagination

  useEffect(() => {
    setScrollConfig({
      ...scrollConfig,
      y: scroll?.y
        ? scroll.y
        : window.tnym.getHeight() -
          headerHeight -
          paginationHeight -
          offset -
          10,
    })
  }, [offset, scroll, headerHeight, paginationHeight])

  const [vt] = useVT(
    () => ({
      onScroll:
        typeof onEndReached === 'function'
          ? ({ isEnd }) => {
              if (isEnd) {
                onEndReached()
              }
            }
          : undefined,
      scroll: { y: scrollConfig.y },
      debug,
    }),
    [dataSource, onEndReached, scrollConfig]
  )

  const style = useMemo(
    () => ({
      cursor: 'pointer',
      ...props.style,
    }),
    [props.style]
  )

  /**
   * This handles the current sortOrder value for columns (also nested ones).
   * It uses the sorter prop that is set on the table to determine if the respective column is sorted.
   * If it finds sorter[columnKey].order it will use that value, otherwise it will use the defaultSortOrder
   * value that is set in columns.
   *
   * It also enhances each column with a default value of 1 for sorter.multiple. This is only done when at least one
   * column has sorter.multiple set. It is necessary to do this for the antd table sorting to work properly
   * and eliminates the necessity to do this for each sorter if they don't need a 'multiple' hierarchy.
   */
  const columnsWithState = useMemo(() => {
    const hasSortersMultipleProp = columns.some((column) => {
      if (typeof column.children !== 'undefined') {
        return column.children.some(
          (child) => typeof child.sorter?.multiple !== 'undefined'
        )
      } else {
        return typeof column.sorter?.multiple !== 'undefined'
      }
    })

    return enhanceColumns({
      columns,
      filters,
      getThemedColor,
      hasSortersMultipleProp,
      hasSmallRows,
      hasZebraRows,
      sorterState,
    })
  }, [
    columns,
    filters,
    getThemedColor,
    hasSmallRows,
    hasZebraRows,
    sorterState,
  ])

  return (
    <Table
      columns={columnsWithState}
      dataSource={dataSource}
      scroll={scrollConfig}
      components={vt}
      onChange={onChange}
      pagination={
        isEndless
          ? false
          : {
              defaultPageSize: '50',
              pageSizeOptions: ['10', '25', '50', '75', '100', '150', '200'],
            }
      }
      style={style}
      {...props}
    />
  )
}
