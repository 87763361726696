import { grey } from '@ant-design/colors'
import { CheckOutlined } from '@ant-design/icons'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { TODO_ENTITY_TYPE, TODO_STATUS } from '@tellonym/enums/lib/Tell'
import { Button, Card, Empty, List, Segmented, Select, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Box, FlatList, ScrollView, _, styleSheets, theme } from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import { completeTodo, refreshTodos } from '../actionsV2'
import { supportedLanguages } from '../constants'
import { useTodosQuery } from '../queries'

export const TodoItem = ({
  item,
  hasReferenceName,
  onPressComplete: onPressCompleteCallback,
  width = 280,
}) => {
  const dispatch = ReactRedux.useDispatch()
  const location = useLocation()

  const isTodoPage = location.pathname?.includes('/todos')

  const isGroup = item?.type === TODO_ENTITY_TYPE.GROUP

  const langString = languageEnumAsString[item?.language]

  const onPressComplete = () => {
    dispatch(completeTodo({ todoId: item?.id }))
    onPressCompleteCallback?.()
  }

  const onPressItem = (e) => {
    if (isTodoPage) {
      _.openRoute(
        `/artificialtells_v2/${langString}/${isGroup ? 'group' : 'topic'}/${
          item.referenceId
        }`,
        e
      )
    }
  }

  return (
    <List.Item style={{ paddingTop: 0, display: 'flex' }}>
      <Card
        onClick={onPressItem}
        bodyStyle={{ padding: 12 }}
        style={{
          cursor: isTodoPage ? 'pointer' : undefined,
          width,
          backgroundColor: theme.colors.antdBackgroundElevated,
        }}>
        {item?.content ? (
          <>
            <Box flexDirection="row" alignItems="center" transparent>
              <Box flex="1" marginRight={10} transparent>
                <Box
                  transparent
                  flexDirection="row"
                  justifyContent="space-between">
                  <Box transparent flexDirection="row">
                    <Typography.Text style={{ paddingRight: 5 }}>
                      {isGroup ? 'Group:' : 'Topic:'}
                    </Typography.Text>
                    <Typography.Link
                      href={`/artificialtells_v2/${langString}/${
                        isGroup ? 'group' : 'topic'
                      }/${item.referenceId}`}>
                      {item.referenceId}
                    </Typography.Link>
                    <Typography.Text
                      style={{
                        color: grey[1],
                        paddingLeft: 5,
                      }}>{`- ${langString}`}</Typography.Text>
                  </Box>
                  <Typography.Text style={{ fontSize: 12, marginLeft: 8 }}>
                    {`Due ${dayjs(item.dueDate).fromNow()}`}
                  </Typography.Text>
                </Box>
                <Box transparent flexDirection="row">
                  <Box transparent flex={1}>
                    <ScrollView style={{ maxHeight: 120 }}>
                      {hasReferenceName && (
                        <Typography.Text
                          style={{ color: grey[1], marginBottom: 4 }}>
                          {item.referenceName}
                        </Typography.Text>
                      )}
                      <Typography.Text>{item.content}</Typography.Text>
                    </ScrollView>
                  </Box>

                  {item.status === TODO_STATUS.OPEN && (
                    <Button
                      onClick={onPressComplete}
                      icon={<CheckOutlined />}
                      shape="circle"
                      style={{ alignSelf: 'flex-end', marginLeft: 8 }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              flexDirection="row"
              justifyContent="space-between"
              marginTop={8}
              transparent>
              <Typography.Text style={{ fontSize: 12 }}>
                {item.status === TODO_STATUS.DONE
                  ? `Completed by: ${item.completedByUsername}`
                  : ''}
              </Typography.Text>
            </Box>
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No open todo"
            style={{ marginTop: 0, marginBottom: 0 }}
          />
        )}
      </Card>
    </List.Item>
  )
}

const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Open', value: TODO_STATUS.OPEN },
  { label: 'Done', value: TODO_STATUS.DONE },
]

const languageOptions = supportedLanguages.reduce(
  (acc, curr) => [
    ...acc,
    { label: curr, value: langDetectObjectsByType1[curr] },
  ],
  [{ label: 'All', value: 'all' }]
)

const Header = ({ onChangeLanguage, onChangeStatus, language, status }) => (
  <Box
    flexDirection="row"
    alignItems="center"
    style={{ marginTop: 24, marginBottom: 24 }}>
    <Box flexDirection="row" alignItems="center">
      <Typography.Text style={{ paddingRight: 5 }}>Status:</Typography.Text>
      <Segmented
        options={statusOptions}
        value={status}
        onChange={onChangeStatus}
      />
    </Box>

    <Box
      flexDirection="row"
      alignItems="center"
      style={styleSheets.margin.left[24]}>
      <Typography.Text style={{ paddingRight: 5 }}>Language:</Typography.Text>
      <Select
        options={languageOptions}
        onChange={onChangeLanguage}
        value={language}
      />
    </Box>
  </Box>
)

export const PageArtificialTellsTodos = () => {
  const dispatch = ReactRedux.useDispatch()

  const [status, setStatus] = React.useState(TODO_STATUS.OPEN)
  const [completedCount, setCompletedCount] = React.useState(0)
  const [language, setLanguage] = React.useState('all')

  const { data, isLoading, fetchNextPage, refetch } = useTodosQuery({
    language: language === 'all' ? undefined : language,
    status,
  })

  const actions = React.useMemo(
    () => ({
      fetch: (payload) => {
        fetchNextPage({
          pageParam: payload,
          cancelRefetch: false,
        })
      },
      refresh: (payload) => {
        refetch(payload)
      },
    }),
    [fetchNextPage, refetch]
  )

  const onChangeLanguage = React.useCallback(
    (value) => {
      setLanguage(value)
    },
    [setLanguage]
  )

  const onChangeStatus = React.useCallback(
    (value) => {
      setStatus(value)
    },
    [setStatus]
  )

  const onPressComplete = React.useCallback(() => {
    setCompletedCount((c) => c + 1)
  }, [setCompletedCount])

  React.useEffect(() => {
    const l = language === 'all' ? undefined : language

    dispatch(refreshTodos({ language: l, status }))
  }, [dispatch, language, status, completedCount])

  return (
    <FlatList
      items={data}
      component={TodoItem}
      actions={actions}
      isRefreshing={isLoading}
      contentContainerStyle={{ alignItems: 'center' }}
      extraProps={{ hasReferenceName: true, width: 600, onPressComplete }}
      renderHeader={() => (
        <Header
          onChangeLanguage={onChangeLanguage}
          onChangeStatus={onChangeStatus}
          language={language}
          status={status}
        />
      )}
    />
  )
}
