/**
 * Definitions for global variables
 */

// necessary to turn this file into an ES module
// https://stackoverflow.com/a/68452689
export {}

declare global {
  interface Window {
    tnym: {
      getWidth: () => number
      getHeight: () => number
      getFullHeight: () => number
      isAndorid: boolean
      isDesktop: () => boolean
      logProduction: () => void
      renderApp: () => void
      whoami: {
        browser: string
        version: string
      }
    }
  }
}

window.tnym = window.tnym || ({} as Window['tnym'])
window.tnym.getWidth = () => document.documentElement.clientWidth
window.tnym.getHeight = () => document.documentElement.clientHeight - 50
window.tnym.getFullHeight = () => document.documentElement.clientHeight
window.tnym.isAndorid =
  window.navigator.userAgent.toLowerCase().indexOf('android') > -1
window.tnym.isDesktop = () => document.documentElement.clientWidth >= 768
window.tnym.logProduction = () => {}
window.tnym.renderApp = () => {}
window.tnym.whoami = (() => {
  // eslint-disable-next-line prefer-const
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return {
      browser: 'ie',
      version: tem[1],
    }
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
    if (tem != null) {
      return {
        browser: tem.indexOf('Edge') > -1 ? 'edge' : 'opera',
        version: tem[2],
      }
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  // eslint-disable-next-line no-cond-assign
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return {
    browser: M[0].toLowerCase(),
    version: M[1],
  }
})()
