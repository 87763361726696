import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { Dropdown } from 'antd'
import React, { useMemo } from 'react'
import * as ReactRedux from 'react-redux'
import { Box, TableEndlessScroll, View, _, styleSheets } from '../../common'
import { editGroup } from '../actionsV2'
import { getHeaderMode } from '../selectorsV2'
import { ModalMoveGroupToOtherTopic } from './ModalMoveGroupToOtherTopic'
import { SharedColumns, Stat, useColumnSearchProps } from './SharedColumns'

export const ArtificialTellTopicGroupsTable = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const searchProps = useColumnSearchProps('name')

  const columns = useMemo(
    () =>
      props.columns ?? [
        {
          ...SharedColumns.id,
          sorter: { multiple: 1, compare: SharedColumns.id.sorter },
          /**
           * This allows to pass specific ids that should be filtered for
           * Requires onPressResetFilter to be passed as well
           */
          ...(props.isDataSourceFiltered
            ? {
                filtered: true,
                filters: [],
                filterDropdownOpen: false,
                onFilterDropdownOpenChange: (open) => {
                  props.onPressResetFilter?.(open)
                },
              }
            : {}),
        },
        SharedColumns.status,
        SharedColumns.groupDepth,
        SharedColumns.groupType,
        {
          title: 'Question',
          dataIndex: 'id',
          key: 'name',
          width: 280,
          render: (id, group) => <View>{group.name}</View>,
          ...searchProps,
          sorter: (a, b) => {
            const textA = a.name?.toLowerCase()
            const textB = b.name?.toLowerCase()

            if (textA < textB) {
              return -1
            }

            if (textA > textB) {
              return 1
            }

            return 0
          },
        },
        SharedColumns.groupTotalSent,
        {
          title: 'Answer Rate',
          dataIndex: 'id',
          key: 'answerRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.answerRate.amount - b.analytics.answerRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.answerRate.amount}
                change={group.analytics.answerRate.change}
              />
            )
          },
        },
        {
          title: 'Median Length',
          dataIndex: 'id',
          key: 'p50Length',
          width: 80,
          sorter: (a, b) =>
            a.analytics.p50Length.amount - b.analytics.p50Length.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                value={group.analytics.p50Length.amount}
                change={group.analytics.p50Length.change}
              />
            )
          },
        },
        {
          title: 'Share Rate',
          dataIndex: 'id',
          key: 'shareRate',
          width: 80,
          sorter: (a, b) =>
            a.analytics.shareRate.amount - b.analytics.shareRate.amount,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <Stat
                isPercentage
                value={group.analytics.shareRate.amount}
                change={group.analytics.shareRate.change}
              />
            )
          },
        },
        {
          title: 'Reuse Days',
          dataIndex: 'id',
          key: 'reaskInDays',
          width: 80,
          filterMultiple: false,
          filters: [
            {
              text: 'Greater than 0',
              value: 1,
            },
          ],
          onFilter: (value, group) => {
            return value === 1 ? group.reaskInDays > 0 : true
          },
          onHeaderCell: () => ({
            onClick: () => {
              props.setTableState((tableState) => ({
                ...tableState,
                filters: {
                  ...tableState.filters,
                  reaskInDays: Array.isArray(tableState.filters.reaskInDays)
                    ? undefined
                    : [1],
                },
              }))
            },
          }),
          render: (id, group) => (
            <View style={styleSheets.center}>{group.reaskInDays}</View>
          ),
        },
        {
          title: 'Vars',
          dataIndex: 'id',
          key: 'activeVariancesCountTotal',
          width: 80,
          sorter: (a, b) =>
            a.activeVariancesCount.total - b.activeVariancesCount.total,
          filterMultiple: false,
          filters: [
            {
              text: 'Only Female Variances',
              value: 1,
            },
            {
              text: 'Only Male Variances',
              value: 2,
            },
            {
              text: 'No Both Variances',
              value: 3,
            },
          ],
          onFilter: (value, group) => {
            switch (value) {
              case 1:
                return (
                  group.activeVariancesCount.both === 0 &&
                  group.activeVariancesCount.male === 0
                )
              case 2:
                return (
                  group.activeVariancesCount.both === 0 &&
                  group.activeVariancesCount.female === 0
                )
              case 3:
                return group.activeVariancesCount.both === 0
              default:
                return true
            }
          },
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            return (
              <View style={styleSheets.center}>
                {group.activeVariancesCount.total}
              </View>
            )
          },
        },
        SharedColumns.groupSenderHint,
        SharedColumns.lastUpdatedAt,
        {
          title: 'Actions',
          dataIndex: 'id',
          key: 'actions',
          width: 80,
          render: (id, group) => {
            if (group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED) {
              return null
            }

            const items = [
              { key: 'move', label: 'Move group to other topic' },
              {
                key: 'inactiveCurrentLanguage',
                label: 'Set inactive for selected language',
              },
              {
                key: 'inactiveAllLanguages',
                label: 'Set inactive for all languages',
              },
              group.status === ARTIFICIAL_TELL_STATUS.INACTIVE && {
                key: 'deleteAllLanguages',
                label: 'Delete for all languages',
              },
            ].filter(Boolean)

            const onClick = ({ key, domEvent }) => {
              domEvent.stopPropagation()

              switch (key) {
                case 'move': {
                  ModalMoveGroupToOtherTopic.show({
                    topicId: props.topicId,
                    group,
                  })
                  break
                }

                case 'inactiveCurrentLanguage': {
                  dispatch(
                    editGroup({
                      id: group.id,
                      status: ARTIFICIAL_TELL_STATUS.INACTIVE,
                      languageForChangingStatus:
                        langDetectObjectsByType1[props.language],
                    })
                  )
                  break
                }

                case 'inactiveAllLanguages': {
                  dispatch(
                    editGroup({
                      id: group.id,
                      status: ARTIFICIAL_TELL_STATUS.INACTIVE,
                      languageForChangingStatus: 'ALL',
                    })
                  )

                  break
                }

                case 'deleteAllLanguages': {
                  if (group.status === ARTIFICIAL_TELL_STATUS.INACTIVE) {
                    dispatch(
                      editGroup({
                        id: group.id,
                        status: ARTIFICIAL_TELL_STATUS.DELETED,
                        languageForChangingStatus: 'ALL',
                      })
                    )
                  }
                  break
                }

                default:
                  break
              }
            }

            return (
              <Box alignItems="center" justifyContent="center" transparent>
                <Dropdown.Button
                  size="small"
                  onClick={(e) => onClick({ key: 'move', domEvent: e })}
                  menu={{ items, onClick }}
                  style={{ width: 55 }}>
                  Move
                </Dropdown.Button>
              </Box>
            )
          },
        },
      ],
    [dispatch, props, searchProps]
  )

  const onRow = React.useCallback(
    (group) => {
      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${props.language ?? 'en'}/group/${group.id}${
          headerMode ? `?headerMode=${headerMode}` : ''
        }`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [props.language, headerMode]
  )

  return (
    <TableEndlessScroll
      {...props}
      hasSmallRows
      hasZebraRows
      columns={columns}
      onRow={onRow}
    />
  )
}
