import { getIsLoggedIn } from '@tellonym/core/app/selectors'
import { logout } from '@tellonym/core/profile/actions'
import { Drawer, Menu, MenuProps } from 'antd'
import React from 'react'
import type { ScrollView as ScrollViewType } from 'react-native-web'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore this is correct
import _ScrollView from 'react-native-web/dist/exports/ScrollView'
import * as ReactRedux from 'react-redux'
import { useLocation } from 'react-router'
import { toggleSidemenu } from '../../app/actions'
import { getIsSidemenuShown, getPermissions } from '../../app/selectors'
import { View } from '../../common/components/View'
import * as helpers from '../../common/helpers'
import { history } from '../../common/history'
import * as hooks from '../../common/hooks'
import { theme } from '../../common/styles/theme'
import { routes, sidemenu } from '../routes'
import { Header } from './Header'

const SIDEMENU_ANIMATION_DURATION = 200 // milliseconds
const SIDEMENU_WIDTH = 175

const ScrollView = _ScrollView as typeof ScrollViewType

type onPressParams = Parameters<MenuProps['onClick']>[0] & {
  isAuxPress?: boolean
}

const MenuComponent = ({ isMobile = false }: { isMobile?: boolean }) => {
  const dispatch = ReactRedux.useDispatch()
  const location = useLocation()
  const permissions = ReactRedux.useSelector(getPermissions)

  const getPath = (key: string) => {
    const queryParams = location.pathname.includes('/artificialtells_v2')
      ? undefined
      : location.search.split('?')[1]

    const filteredKey = key.replace(/:[^/]+\//g, '') // Remove any :variable/ patterns
    const path =
      routes.data[key]?.getPath?.() ??
      `${filteredKey}${queryParams ? `?${queryParams}` : ''}`

    return path
  }

  const onPress = ({ key, domEvent, isAuxPress = false }: onPressParams) => {
    if (isMobile) {
      dispatch(toggleSidemenu())
    }

    if (key === 'logout') {
      dispatch(logout())
      return
    }

    const path = getPath(key)

    if (domEvent.metaKey || domEvent.ctrlKey || isAuxPress) {
      window.open(path, '_blank')
      return
    }

    history.push(path)
  }

  const onPressAux = (event: Parameters<MenuProps['onAuxClick']>[0]) => {
    const fullKey = (event.target as any)?.closest('[data-menu-id]')?.dataset
      .menuId

    if (!fullKey) {
      return
    }

    // Extract the actual route key using regex
    // This matches everything after 'rc-menu-uuid-' followed by numbers and dashes
    const key = `/${fullKey.replace(/^rc-menu-uuid-[\d-]+\//, '')}`

    onPress({
      key,
      domEvent: event,
      item: undefined,
      isAuxPress: true,
    } as unknown as onPressParams)
  }

  const items = React.useMemo<MenuProps['items']>(() => {
    const menuItems = sidemenu.ids.map((sectionId) => {
      const {
        permissions: requiredPermission,
        name,
        routes: sideMenuRoutes,
      } = sidemenu.data[sectionId]
      const hasPermission = requiredPermission
        ? helpers.checkPermission(
            requiredPermission,
            permissions as Array<string>
          )
        : true

      if (!hasPermission) {
        return null
      }

      return {
        key: sectionId,
        type: 'group',
        label: name,
        children: [
          ...sideMenuRoutes.map((id) => {
            if (typeof id === 'string') {
              return {
                key: id,
                label: routes.data[id].name,
              }
            }

            /**
             * This is to support nested submenus
             */
            return {
              key: id.name,
              label: id.name,
              children: id.children.map((child) => ({
                key: child,
                label: routes.data[child].name,
                style: { paddingLeft: 32 },
              })),
            }
          }),
          { type: 'divider' },
        ],
      }
    })

    return [
      ...menuItems,
      { type: 'divider' },
      { key: 'logout', label: 'Logout', danger: true },
      null,
    ]
  }, [permissions])

  const defaultOpenKeys = sidemenu.ids.reduce((acc, key) => {
    sidemenu.data[key].routes.forEach((route) => {
      if (typeof route === 'object' && route.isOpenByDefault) {
        acc.push(route.name)
      }
    })

    return acc
  }, [])

  const matchRouteWithParams = React.useCallback(
    (routeKey: string) => {
      // Handle routes with parameters (e.g., '/path/:param')
      const routePattern = routeKey.replace(/:\w+/g, '[^/]+')
      const regex = new RegExp(`^${routePattern}$`)
      return regex.test(location.pathname)
    },
    [location.pathname]
  )

  return (
    <Menu
      onClick={onPress}
      onAuxClick={onPressAux}
      activeKey={location.pathname}
      items={items}
      selectedKeys={[
        // Find matching route that includes parameters
        Object.keys(routes.data).find((key) => matchRouteWithParams(key)) ||
          location.pathname,
      ]}
      mode="inline"
      defaultOpenKeys={defaultOpenKeys}
    />
  )
}

const SideMenuComponent = ({ children }: { children: React.ReactNode }) => {
  const dispatch = ReactRedux.useDispatch()
  const isMobile = window.tnym.isDesktop() === false

  const getThemedColor = hooks.useThemedColor()

  const isSidemenuShown = ReactRedux.useSelector(getIsSidemenuShown) as boolean
  const isLoggedIn = ReactRedux.useSelector(getIsLoggedIn) as boolean

  if (!isLoggedIn) {
    return children
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
        height: '100%',
        paddingTop: Header.height,
      }}>
      {isMobile ? (
        <Drawer
          placement="left"
          open={isSidemenuShown}
          onClose={() => dispatch(toggleSidemenu())}
          width={SIDEMENU_WIDTH}
          bodyStyle={{ padding: 0 }}>
          <MenuComponent isMobile />
        </Drawer>
      ) : (
        <>
          <View
            style={{
              position: 'fixed',
              boxShadow: theme.styles.shadow,
              top: Header.height,
              left: isSidemenuShown ? 0 : -SIDEMENU_WIDTH,
              bottom: 0,
              backgroundColor: getThemedColor(
                theme.colors.background,
                theme.colors.antdDarkModeBackground
              ),
              zIndex: 98,
              width: SIDEMENU_WIDTH,
              justifyContent: 'space-between',
              transition: `left ${SIDEMENU_ANIMATION_DURATION}ms ease-in-out`,
            }}>
            <ScrollView style={{ flex: 1 }}>
              <MenuComponent />
            </ScrollView>
          </View>
          <View
            style={{
              minWidth: SIDEMENU_WIDTH,
              transition: `min-width ${SIDEMENU_ANIMATION_DURATION}ms ease-in-out`,
              ...(isSidemenuShown ? {} : { minWidth: 0 }),
            }}
          />
        </>
      )}
      {children}
    </View>
  )
}

SideMenuComponent.width = window.tnym.isDesktop() ? SIDEMENU_WIDTH : 0

export const SideMenu = SideMenuComponent
