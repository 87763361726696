import { Line } from '@ant-design/plots'
import { useDebounceCallback } from '@react-hook/debounce'
import { headerModes } from '@tellonym/core/artificialTells/constants'
import { normalize } from '@tellonym/core/helpers'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_SENDER_HINT_TYPE,
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_TARGET_GENDER,
} from '@tellonym/enums/lib/Tell'
import {
  Button,
  Input,
  InputNumber,
  Select,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, BoxSecondary, FlatList, colors, hooks, theme } from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { updateLocationSearch } from '../../common/helpers'
import {
  createVariance,
  editGroup,
  refreshGroup,
  refreshVarianceAnalytics,
  setHeaderMode,
  setShouldShowDeletedVariances,
} from '../actionsV2'
import { useGroupStatsGraphQuery } from '../queries'
import {
  getDataMode,
  getHeaderMode,
  getIsRefreshingGroupDetails,
  getIsRefreshingVarianceAnalytics,
  getLanguage,
  getShouldShowDeletedVariances,
} from '../selectorsV2'
import { ArtificialTellItemV2 } from './ArtificialTellItemV2'
import { BreadcrumbHeader } from './BreadcrumbHeader'
import { ButtonModalCreateTodo } from './ButtonModalCreateTodo'
import { GptAnalysisBox } from './GptAnalysisBox'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { TodoItem } from './PageArtificialTellsTodos'
import { PerformanceCheckIns } from './PerformanceCheckIns'

const styles = {
  clickable: { cursor: 'pointer' },
}

const TAB_HEIGHT = 196

const useEditGroup = (group) => {
  const dispatch = ReactRedux.useDispatch()

  return React.useCallback(
    (payload) => {
      dispatch(
        editGroup({
          id: group.id,
          ...payload,
        })
      )
    },
    [dispatch, group]
  )
}

const ActionsSection = () => {
  const dispatch = ReactRedux.useDispatch()

  const shouldShowDeletedVariances = ReactRedux.useSelector(
    getShouldShowDeletedVariances
  )

  const onPressShowDeletedVariances = () => {
    dispatch(setShouldShowDeletedVariances(!shouldShowDeletedVariances))
  }

  return (
    <Box width={150} transparent>
      <Button
        type={shouldShowDeletedVariances ? 'primary' : 'default'}
        size="small"
        onClick={onPressShowDeletedVariances}
        style={{
          fontSize: 12,
          marginBottom: 12,
          backgroundColor: shouldShowDeletedVariances ? 'green' : undefined,
          borderColor: shouldShowDeletedVariances ? 'green' : undefined,
        }}>
        {shouldShowDeletedVariances
          ? 'Show active variances'
          : 'Show deleted variances'}
      </Button>
    </Box>
  )
}

const ShTypeSelect = ({ onChange, senderHintType }) => {
  return (
    <Tooltip title="Sender Hint Type">
      <Select
        size="small"
        options={[
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT
                  ]
                }
                text="Not set"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT,
          },
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE
                  ]
                }
                text="None"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE,
          },
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC
                  ]
                }
                text="Pretend Organic"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC,
          },
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL
                  ]
                }
                text="Reveal"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL,
          },
        ]}
        onChange={onChange}
        defaultValue={senderHintType}
        style={{ width: 140, marginRight: 12 }}
      />
    </Tooltip>
  )
}

export const TypeSelect = ({ type, onChange }) => (
  <Tooltip title="Group Type">
    <Select
      size="small"
      options={[
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]
              }
              text="Organic"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]
              }
              text="Simple"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.OPEN]
              }
              text="Open"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.SHARE]
              }
              text="Share"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.SHARE,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT]
              }
              text="Statement"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
        },
      ]}
      onChange={onChange}
      defaultValue={type}
      style={{ width: 120, marginRight: 12 }}
    />
  </Tooltip>
)

export const DepthSelect = ({ onChange, depth }) => (
  <Tooltip title="Question Depth">
    <Select
      size="small"
      options={[
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellDepth[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
              }
              text="Intro"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellDepth[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
              }
              text="Deep"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP,
        },
      ]}
      onChange={onChange}
      defaultValue={depth}
      style={{ width: 100, marginRight: 12 }}
    />
  </Tooltip>
)

const NameSection = ({ group }) => {
  const [groupName, setGroupName] = React.useState(group.name || '')

  const dispatch = ReactRedux.useDispatch()

  const editGroup = useEditGroup(group)

  const saveGroupName = useDebounceCallback(
    React.useCallback(
      (groupName) => {
        dispatch(
          editGroup({
            id: group.id,
            name: groupName,
          })
        )
      },
      [dispatch, group.id]
    ),
    1500,
    false
  )

  const onChangeGroupName = (e) => {
    setGroupName(e.target.value)
    saveGroupName(e.target.value)
  }

  React.useEffect(() => {
    if (groupName === '' && group.name) {
      setGroupName(group.name)
    }
  }, [groupName, group.name])

  return (
    <Box flex={1} transparent>
      <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
        Group Name
      </Typography.Text>

      <Input.TextArea
        value={groupName}
        onChange={onChangeGroupName}
        autoSize={{ minRows: 1, maxRows: 1 }}
        style={{ width: '100%' }}
      />
    </Box>
  )
}

const ChangeGroupPropertiesSection = ({ group }) => {
  const isRepeated = group.reaskInDays > 0

  const editGroup = useEditGroup(group)

  const toggleIsRepeated = () => {
    if (group.reaskInDays > 0) {
      editGroup({ reaskInDays: 0 })
    } else {
      editGroup({ reaskInDays: 1 })
    }
  }

  const onChangeReaskInDays = (reaskInDays) => {
    if (reaskInDays !== group.reaskInDays) {
      editGroup({ reaskInDays })
    }
  }

  return (
    <Box
      flexDirection="row"
      marginTop={12}
      minWidth={556} /* This is corresponding with the width of
      ChangeGroupPropertiesSection to set the whole containers width */
      transparent
      justifyContent="space-between">
      <Box transparent flexDirection="row">
        <ShTypeSelect
          senderHintType={group.senderHintType}
          onChange={(type) => {
            editGroup({ senderHintType: type })
          }}
        />
        <TypeSelect
          onChange={(type) => {
            editGroup({ type })
          }}
          type={group.type}
        />
        <DepthSelect
          onChange={(depth) => {
            editGroup({ depth })
          }}
          depth={group.depth}
        />
      </Box>

      {isRepeated ? (
        <InputNumber
          disabled={Boolean(group.reaskInDays) === false}
          min={0}
          max={365}
          value={group.reaskInDays ?? 0}
          onChange={onChangeReaskInDays}
          addonBefore={
            <Tooltip
              placement="topLeft"
              title="Click 'Reuse after' to disable repeated">
              <Typography.Text
                onClick={toggleIsRepeated}
                style={{ cursor: 'pointer' }}>
                Reuse after
              </Typography.Text>
            </Tooltip>
          }
          addonAfter="days"
          size="small"
          style={{ maxWidth: 200 }}
        />
      ) : (
        <Button size="small" onClick={toggleIsRepeated}>
          Set repeated
        </Button>
      )}
    </Box>
  )
}

const DescriptionSection = ({ group }) => {
  const dispatch = ReactRedux.useDispatch()

  const [description, setDescription] = React.useState(group.description || '')

  const saveDescription = useDebounceCallback(
    React.useCallback(
      (description) => {
        dispatch(
          editGroup({
            id: group.id,
            description,
          })
        )
      },
      [dispatch, group]
    ),
    1500,
    false
  )

  React.useEffect(() => {
    if (description === '' && group.description) {
      setDescription(group.description)
    }
  }, [description, group.description])

  const onChangeDescription = (e) => {
    setDescription(e.target.value)
    saveDescription(e.target.value)
  }

  return (
    <Box transparent paddingTop={16}>
      <Input.TextArea
        value={description}
        onChange={onChangeDescription}
        placeholder="Description"
        autoSize={{ minRows: 1, maxRows: 3 }}
        style={{ width: '100%' }}
      />
    </Box>
  )
}

const Suggestions = ({ suggestions }) => {
  return (
    <Box position="relative" transparent>
      <Box justifyContent="center" transparent>
        <Typography.Text style={{ marginLeft: 48, fontWeight: 'bold' }}>
          Suggestions
        </Typography.Text>
      </Box>
      <Box position="absolute" top={65}>
        {suggestions?.map((suggestion) => {
          return (
            <Box
              key={suggestion.id}
              paddingVertical={12}
              paddingHorizontal={48}
              alignItems="center">
              <Typography.Text style={{ flex: 7 }}>
                {suggestion.content}
              </Typography.Text>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

const HeaderEditTab = ({ group }) => {
  const language = ReactRedux.useSelector(getLanguage)

  const isLoaded = Boolean(group.id)

  return (
    <Box flexDirection="row" justifyContent="space-between" transparent>
      <Box transparent marginRight={12} flexGrow={1}>
        <Box transparent>
          <NameSection isLoading={!isLoaded} group={group} />
          <ChangeGroupPropertiesSection group={group} />
          <DescriptionSection group={group} />
        </Box>
      </Box>

      <Box
        transparent
        marginRight={12}
        justifyContent="flex-end"
        alignItems="center"
        flexGrow={0.5}>
        <ActionsSection group={group} language={language} />
      </Box>

      <Box
        transparent
        alignItems="flex-end"
        justifyContent="space-between"
        flexGrow={1}>
        <TodoItem item={group.todo} />

        <ButtonModalCreateTodo group={group} language={language} size="small" />
      </Box>
    </Box>
  )
}

const GroupStats = ({ group }) => {
  const dispatch = ReactRedux.useDispatch()

  const isRefreshingGroupDetails = ReactRedux.useSelector(
    getIsRefreshingGroupDetails
  )

  const onChangeDataMode = () => {
    dispatch(refreshGroup({ groupId: group.id }))
  }

  return (
    <Box
      transparent
      justifyContent="space-between"
      alignItems="center"
      minWidth={180}
      maxWidth={260}
      flexGrow={1}>
      <MedianDataForDataMode
        data={[
          {
            texts: [
              'Total Sent:',
              'Answer Rate:',
              'P50 Length:',
              'P80 Length:',
              'Share Rate:',
            ],
            values: [
              group.analytics.totalSent,
              group.analytics.answerRate,
              group.analytics.p50Length,
              group.analytics.p80Length,
              group.analytics.shareRate,
            ],
          },
        ]}
        isLoading={isRefreshingGroupDetails}
        onChange={onChangeDataMode}
        backgroundColor="transparent"
        hasTitle={false}
      />
    </Box>
  )
}

const HeaderStatsTab = ({ group }) => {
  const language = ReactRedux.useSelector(getLanguage)

  return (
    <Box flexDirection="row" justifyContent="space-between" transparent>
      <Box
        transparent
        marginRight={24}
        flexGrow={1}
        maxWidth={500}
        alignItems="center">
        <GroupStats group={group} />
      </Box>

      <GptAnalysisBox group={group} language={language} />

      <PerformanceCheckIns
        data={group.performanceChecks}
        groupId={group.id}
        language={language}
        isLoading={!group?.id}
      />

      <Box transparent alignItems="flex-end" justifyContent="space-between">
        <TodoItem item={group.todo} />

        <Box flexDirection="row" justifyContent="flex-end" transparent>
          <ButtonModalCreateTodo group={group} language={language} />
        </Box>
      </Box>
    </Box>
  )
}

const HeaderGraphTab = ({ group }) => {
  const dataMode = ReactRedux.useSelector(getDataMode)
  const language = ReactRedux.useSelector(getLanguage)
  const [lastXDays, setLastXDays] = React.useState(30)

  const { data, isLoading } = useGroupStatsGraphQuery({
    groupId: group?.id,
    language,
    dataMode,
    lastXDays,
  })

  const dataSource = React.useMemo(() => {
    if (!data) {
      return []
    }

    const result = data.reduce((acc, item) => {
      const { date, ...restKeys } = item

      Object.keys(restKeys).forEach((key) => {
        const value = item[key]

        if (value === null) {
          return
        }

        acc.push({
          date,
          value,
          dataType: key,
        })
      })

      return acc
    }, [])

    return result.reverse()
  }, [data])

  const legendKeys = Object.keys(data?.[0] ?? {}).filter(
    (key) => key !== 'date'
  )

  const lineConfig = {
    height: TAB_HEIGHT - 8,
    xField: 'date',
    yField: 'value',
    seriesField: 'dataType',
    padding: 'auto',
    connectNulls: false,
    xAxis: {
      label: {
        formatter: (v) => dayjs(v).format('DD.MM.YY'),
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'right',
      flipPage: false,
      selected: {
        ...legendKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        answerRate: true,
        shareRate: true,
      },
    },
    tooltip: {
      position: 'right',
      offset: 120,
    },
    interactions: [{ type: 'legendFilter' }, { type: 'elementSelect' }],
  }

  const onPressQuickTimeCreator = (time) => () => {
    setLastXDays(time)
  }

  return (
    <Box transparent flexDirection="row">
      <Box transparent width={850}>
        <Spin spinning={isLoading}>
          <Line data={dataSource} {...lineConfig} />
        </Spin>
      </Box>

      <Box flex={1} transparent paddingHorizontal={24}>
        <InputNumber
          size="small"
          addonBefore="Last"
          addonAfter="days"
          value={lastXDays}
          onChange={setLastXDays}
          style={{ width: 160 }}
        />

        <Box transparent flexDirection="row" alignItems="center" marginTop={12}>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(7)}
            style={styles.clickable}>
            Last 7d
          </Tag>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(30)}
            style={styles.clickable}>
            Last 14d
          </Tag>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(30)}
            style={styles.clickable}>
            Last 30d
          </Tag>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(90)}
            style={styles.clickable}>
            Last 90d
          </Tag>
        </Box>
      </Box>
    </Box>
  )
}

const GroupHeader = ({ group }) => {
  const dispatch = ReactRedux.useDispatch()

  const language = ReactRedux.useSelector(getLanguage)
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const SelectedTab = React.useMemo(() => {
    switch (headerMode) {
      case headerModes.EDIT:
        return HeaderEditTab
      case headerModes.STATS:
        return HeaderStatsTab
      case headerModes.GRAPH:
        return HeaderGraphTab
      default: {
        dispatch(setHeaderMode(headerModes.STATS))

        return () => null
      }
    }
  }, [dispatch, headerMode])

  const inputRef = React.useRef(null)

  const [content, setContent] = React.useState('')

  const onSubmitNewEntry = () => {
    if (content !== '') {
      dispatch(
        createVariance({
          groupId: group.id,
          content,
          gender: ARTIFICIAL_TELL_TARGET_GENDER.BOTH,
          status: ARTIFICIAL_TELL_STATUS.INACTIVE,
        })
      )

      setContent('')
    }
  }

  hooks.useKeyboardShortcutToSubmit({
    inputRef,
    onSubmit: onSubmitNewEntry,
  })

  hooks.useEscapeKey({
    inputRef,
    onPress: () => {
      setContent('')
    },
  })

  return (
    <BoxSecondary
      hasBottomBorder
      paddingTop={12}
      paddingHorizontal={24}
      borderBottomWidth={0.5}>
      <BreadcrumbHeader hasGraph group={group} language={language} />

      <Box
        transparent
        minHeight={TAB_HEIGHT}
        maxWidth={1600}
        margin="auto"
        width="100%">
        <SelectedTab group={group} />
      </Box>

      <Box flex={1} flexDirection="row" transparent>
        <Box flex={4} transparent>
          <Input.Group compact style={{ paddingTop: 12 }}>
            <Input
              ref={inputRef}
              style={{ width: 'calc(100% - 120px)' }}
              defaultValue={content}
              onChange={(e) => setContent(e.target.value)}
              value={content}
              placeholder="New Entry..."
            />
            <Tooltip title="Pressing ENTER also saves it">
              <Button
                type="bordered"
                onClick={onSubmitNewEntry}
                style={{ width: 120 }}>
                Create Entry
              </Button>
            </Tooltip>
          </Input.Group>
        </Box>
        <Box flex={2} justifyContent="center" transparent>
          <Suggestions suggestions={group.suggestions} />
        </Box>
      </Box>
    </BoxSecondary>
  )
}

const Item = (props) => {
  return (
    <Box
      flexDirection="row"
      paddingTop={8}
      paddingBottom={8}
      justifyContent="center"
      alignItems="center"
      borderWidth={0}
      borderBottomWidth={0.5}
      borderColor={
        props.hasSeparator
          ? theme.colors.borderLightGrey
          : theme.colors.background
      }
      borderStyle="solid"
      transparent>
      <Box flex={4} justifyContent="center">
        <ArtificialTellItemV2 {...props} />
      </Box>
      <Box flex={2} />
    </Box>
  )
}

export const PageArtificialTellsGroupDetailsV2 = ({ match }) => {
  const dispatch = ReactRedux.useDispatch()

  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const [areStatsVisible, setAreStatsVisible] = React.useState(false)

  const isRefreshingVarianceAnalytics = ReactRedux.useSelector(
    getIsRefreshingVarianceAnalytics
  )
  const shouldShowDeletedVariances = ReactRedux.useSelector(
    getShouldShowDeletedVariances
  )

  const groupId = parseInt(match.params.groupId, 10)

  const dataMode = ReactRedux.useSelector(getDataMode)
  const language = ReactRedux.useSelector(getLanguage)

  const { group, items } = ReactRedux.useSelector((state) => {
    const group = state.artificialTellsV2.groupDetails[groupId]

    return {
      group,
      items: normalize(group?.variances ?? []),
    }
  })

  const renderHeader = React.useCallback(
    () => <GroupHeader group={group} />,
    [group]
  )

  const actions = React.useMemo(
    () => ({
      refresh: (payload) =>
        dispatch(
          refreshGroup({
            groupId,
            showDeletedVariances: shouldShowDeletedVariances,
            ...payload,
          })
        ),
    }),
    [dispatch, groupId, shouldShowDeletedVariances]
  )

  const extraProps = React.useMemo(
    () => ({
      areStatsVisible,
      setAreStatsVisible,
      groupStats: {
        answerRate: group?.analytics?.answerRate?.amount,
        totalSent: group?.analytics?.totalSent?.amount,
        dataMode: group?.analytics?.dataMode,
      },
      groupStatus: group?.status,
      isRefreshingVarianceAnalytics,
    }),
    [areStatsVisible, setAreStatsVisible, group, isRefreshingVarianceAnalytics]
  )

  const extraData = React.useMemo(
    () => ({
      areStatsVisible,
      hasMore: false,
      isFetching: false,
      isRefreshing: false,
      group,
    }),
    [group, areStatsVisible]
  )

  React.useEffect(() => {
    updateLocationSearch({ headerMode })
  }, [headerMode])

  React.useEffect(() => {
    if (typeof groupId !== 'undefined' && typeof language !== 'undefined') {
      dispatch(
        refreshGroup(
          { groupId, showDeletedVariances: shouldShowDeletedVariances },
          { shouldDiscardVariances: true }
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, language, shouldShowDeletedVariances])

  React.useEffect(() => {
    if (areStatsVisible) {
      dispatch(
        refreshVarianceAnalytics({
          varianceIds: items.ids,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areStatsVisible, dataMode])

  if (!group?.id) {
    return null
  }

  return (
    <FlatList
      actions={actions}
      component={Item}
      extraData={extraData}
      extraProps={extraProps}
      items={items}
      isRefreshing={false}
      locale={{
        emptyText: items ? null : <Skeleton active={true} />,
      }}
      renderHeader={renderHeader}
    />
  )
}
