// @flow

import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { Grid } from 'antd'
import React from 'react'
import {
  Avatar,
  Box,
  Icon,
  Link,
  moment,
  Text,
  theme,
  View,
} from '../../common'
import { IconVerified } from '../../common/components/IconVerified'
import * as hooks from '../../common/hooks'
import { UserTypeBadge } from './UserTypeBadge'

const LastActive = ({ timestamp }) => (
  <View style={{ marginRight: 24 }}>
    <Text center type="small">
      last active:
    </Text>
    <Text type="small" color={theme.colors.placeholder}>
      {moment(timestamp).fromNow()}
    </Text>
  </View>
)

export const SearchItem = ({ profile }) => {
  const screens = Grid.useBreakpoint()
  const getThemedColor = hooks.useThemedColor()

  return (
    <View
      style={{
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: getThemedColor(
          theme.colors.background,
          colors.black[5]
        ),
        borderRadius: 12,
        paddingVertical: 12,
        paddingHorizontal: 24,
        marginBottom: 12,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0.1px',
        width: screens.lg ? '50%' : '70%',
      }}>
      <Link
        to={`/user/${profile.id}`}
        style={{ alignItems: 'center', flex: 1 }}>
        <Avatar user={profile} />
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            marginLeft: 12,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <View style={{ justifyContent: 'center' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text bold>{profile.displayName}</Text>
              {profile.isVerified && (
                <Box transparent marginLeft={4}>
                  <IconVerified />
                </Box>
              )}
            </View>
            <Text
              color={theme.colors.placeholder}>{`@${profile.username}`}</Text>
          </View>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <LastActive timestamp={profile.lastActiveAllAt} />
            <UserTypeBadge type={profile.type} />
          </View>
        </View>
        <Icon
          icon={faChevronRight}
          style={{
            marginLeft: 24,
            marginRight: 12,
            color: getThemedColor(colors.black[1], colors.white[1]),
          }}
        />
      </Link>
    </View>
  )
}
