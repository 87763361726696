import { colors } from '@tellonym/core/common/colorSystem'
import React from 'react'
import * as hooks from '../hooks'
import { theme } from '../styles/theme'
import { Box } from './Box'

export const BoxSecondary = (props) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Box
      borderWidth={0}
      borderBottomWidth={props.hasBottomBorder ? 1 : 0}
      borderColor={theme.colors.antdBackgroundElevatedBorder}
      borderStyle="solid"
      backgroundColor={getThemedColor(
        theme.colors.antdBackgroundElevated,
        colors.black[5]
      )}
      {...props}
    />
  )
}
